import React from 'react';
import styled from 'styled-components';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';

const RecommendSubtitle = styled.div`
  font-size: 18px;
  letter-spacing: 0.56px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
  color: ${(props) => props.theme.darkblue};
`;

const RecommendTitle = styled.div`
  font-family: 'Montserrat Bold';
  font-size: 32px;
  font-weight: bold;
  line-height: 47px;
  text-align: center;
  margin-bottom: 40px;
  color: ${(props) => props.theme.darkBlue};
`;

const RecommendImg = styled.img`
  box-sizing: border-box;
  border: 15px solid #ffffff;
  background-color: #ffffff;
  border-radius: 100%;
  width: 100%;
  ${'' /* height: 220px; */}
  height: 100%;
  max-width: 220px;
  max-height: 220px;
  margin-bottom: 40px;
  box-shadow: 0 2px 36px 0 rgba(1, 28, 47, 0.1);
`;

const RecommendProduct = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.black};
  letter-spacing: 0.4px;
`;

const RecommendNote = styled.p`
  font-size: 14px;
  line-height: 26px;
  color: ${(props) => props.theme.black};
  letter-spacing: 0.4px;
  opacity: 0.5;
  margin: auto;
  @media (min-width: 768px) {
    width: 70%;
  }
`;

const options = {
  settings: {
    overlayColor: 'rgb(0, 0, 0, 0.8)',
    transitionTimingFunction: 'ease',
    lightboxTransitionSpeed: 0.3,
    slideTransitionSpeed: 0.3,
    buttonsIconPadding: '2px',
    buttonsIconColor: 'rgba(25, 136, 124, 0.5)',
    hideControlsAfter: true,
    disablePanzoom: true,
    showDownloadButton: false
  }
};

const Recommendation = ({ isLoading, recommended }) => {
  const { openLightbox } = useLightbox();
  const images = recommended && recommended.images ? recommended.images : [];
  const img = isLoading ? '/images/loading-foot.png' : recommended ? recommended.img : '/images/default-foot.png';
  const title = recommended && recommended.title ? recommended.title : '';

  return (
    <div>
      <RecommendSubtitle>
        Judging by the choices <br /> you have made so far
      </RecommendSubtitle>
      <RecommendTitle>we recommend</RecommendTitle>
      <RecommendImg src={img} alt='' onClick={() => images.length > 0 ? openLightbox() : null} />
      <RecommendProduct hidden={recommended ? false : true}>
        {title}
      </RecommendProduct>
      <RecommendNote>
        Make your selection and tap NEXT to continue refining your perfect fit.
      </RecommendNote>
      {
        <SRLWrapper options={options}>
          <div id="content-page-one" className="container content zero-opacity untouchable">
            <div className="row">
              {images.length > 0 && (
                images.map((url, ndx) => (
                  <div key={ndx} className="col-md-4 col-6 col-image-half">
                    <img src={url} alt='' />
                  </div>
                ))
              )}
            </div>
          </div>
        </SRLWrapper>
      }
    </div>
  );
};

export default Recommendation;
