import React from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  @media (max-width: 767px) {
    margin-bottom: 1.5rem;
  }
  @media (max-width: 500px) {
    margin-top: 5rem;
  }
`;

const PrevArrow = styled(Link)`
  font-family: 'Montserrat Black';
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  color: ${(props) => props.theme.darkBlue};
  &:hover {
    color: ${(props) => props.theme.black};
  }
  &.disabled {
    pointer-events: none;
    color: ${(props) => props.theme.gray};
    opacity: 0.6;
  }
`;

const SkipArrow = styled(Link)`
  font-family: 'Montserrat Black';
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  margin-left: auto;
  color: ${(props) => props.theme.skyBlue};
  &:hover {
    color: ${(props) => props.theme.black};
  }
  @media (max-width: 500px) {
    position: absolute;
    right: 15px;
    bottom: 75px;
    margin-left: 0;
  }
`;

const NextArrow = styled(Link)`
  font-family: 'Montserrat Black';
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  margin-left: auto;
  color: ${(props) =>
    props.firststep ? props.theme.red : props.theme.darkBlue};
  &:hover {
    color: ${(props) => props.theme.black};
  }
  &.disabled {
    pointer-events: none;
    color: ${(props) => props.theme.gray};
    opacity: 0.6;
  }
`;

const FootAngle = styled.h1`
  font-size: 25px;
  color: ${(props) => props.theme.darkBlue};
  b {
    padding-left: 1rem;
    color: ${(props) => props.theme.black};
  }
  @media (max-width: 767px) {
    position: absolute;
    margin-top: -3rem;
  }
`;

export default (props) => {
  const {
    prevURL,
    nextURL,
    skipURL,
    prevHidden,
    nextHidden,
    firstStep,
    lastPage,
    footAngle,
  } = props;
  return (
    <NavContainer>
      <PrevArrow
        to={prevURL}
        hidden={prevHidden}
        className={!prevURL ? 'disabled' : null}
      >
        <span className='fa fa-chevron-left'> </span> Previous
      </PrevArrow>
      <SkipArrow to={skipURL ? skipURL : ''} hidden={skipURL ? false : true}>
        Skip this step <span className='fa fa-chevron-right'> </span>
      </SkipArrow>
      <NextArrow
        to={nextURL}
        hidden={nextHidden}
        className={!nextURL ? 'disabled' : null}
        firststep={firstStep ? firstStep.toString() : null}
      >
        Next <span className='fa fa-chevron-right'> </span>
      </NextArrow>
      {lastPage ? (
        <FootAngle>
          Your Rear Foot Correction Angle is: <b>{footAngle}˚</b>
        </FootAngle>
      ) : null}
    </NavContainer>
  );
};
