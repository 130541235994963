import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from 'reactstrap';
import Header from '../../components/Header';
import FootPainPoints from '../../components/FootPainPoints';
import Footer, { LastSection } from '../../components/Footer';
import PageNavigator from '../../components/PageNavigator';
import Recommendation from '../../components/Recommendation';

export default ({ selectorChangeHandler, selector, recommended, isLoading }) => {
  return (
    <>
      <Container>
        <Header
          subtitle='Orthotic selector | question eight'
          title='Any structural abnormalities?'
        />
      </Container>
      <LastSection>
        <Container className='mt-4'>
          <Row>
            <StyledCol lg='8' md='7' className='mb-5'>
              <FootPainPoints
                selectorChangeHandler={selectorChangeHandler}
                selector={selector}
                allowEdit={true}
              />
              <InstructionsRow className='mb-5 mt-4'>
                <Col md='12'>
                  <InstructionHeader>
                    Do you have any structural abnormalities? Tap where your problem area is, on the foot image above, and select a description
                  </InstructionHeader>
                  {/* <UploadContainer>
                    <input type='file' id='underside' hidden />
                    <label htmlFor='underside'>
                      <img src='/images/upload-icon.png' alt='' />
                      Upload photos of the underside of your feet (optional)
                    </label>
                  </UploadContainer>
                  <p>
                    These will only be used for the purpose of personalising
                    your orthotic to each foot.
                  </p> */}
                </Col>
              </InstructionsRow>
              <PageNavigator
                prevURL='/kneeposition'
                nextURL='/medicalhistory'
                skipURL='/medicalhistory'
              />
            </StyledCol>
            <Column lg='4' md='5' className='mb-5 text-center'>
              <Recommendation isLoading={isLoading} recommended={recommended} />
            </Column>
          </Row>
        </Container>
        <Footer margintop='5' progressBar={9}/>
      </LastSection>
    </>
  );
};

const InstructionsRow = styled(Row)`
  margin-top: 20px;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 26px;
`;

const InstructionHeader = styled.h4`
  color: #4c5b69;
  font-family: 'Montserrat Bold';
  font-size: 21px;
  font-weight: bold;
  line-height: 28px;
  margin-top: 25px;
`;

const Column = styled(Col)`
  @media (max-width: 420px) {
    flex: unset !important;
    max-width: 100% !important;
    margin-bottom: 30px;
  }
`;

const StyledCol = styled(Col)`
  padding-bottom: 20px;
  border-right: none;
  border-bottom: 1px solid #d8d8d8;
  @media (min-width: 768px) {
    padding-right: 60px !important;
    border-right: 1px solid #d8d8d8;
    border-bottom: none;
  }
`;

// const TagContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   margin-top: 15px;
//   span {
//     margin-right: 10px;
//     margin-top: 15px;
//     @media (max-width: 450px) {
//       width: 100%;
//     }
//   }
// `;

// const UploadContainer = styled.div`
//   margin-top: 45px;
//   margin-bottom: 20px;
//   display: inline-block;
//   label {
//     font-size: 16px;
//     font-family: 'Montserrat Bold';
//     letter-spacing: 0.5px;
//     cursor: pointer;
//     color: ${(props) => props.theme.skyBlue};
//     transition: all 0.2s ease-in-out;
//     display: flex;
//     img {
//       height: 22px;
//       margin-right: 7px;
//       filter: brightness(0.8);
//       transition: all 0.2s ease-in-out;
//     }
//     &:hover {
//       img {
//         filter: none;
//       }
//     }
//   }
// `;