import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';
import styled from 'styled-components';
import '../../node_modules/react-modal-video/scss/modal-video.scss';

const TutorialContainer = styled.div`
  display: block;
  text-align: center;
  margin-bottom: 3rem;
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
`;

const TutorialLink = styled.div`
  display: block;
  margin-bottom: 1.5rem;
  cursor: pointer;
  @media (min-width: 768px) {
    display: inline-block;
  }
`;

const TutorialButton = styled.img`
  width: 250px;
  height: auto;
`;

const TutorialText = styled.span`
  display: block;
  font-family: 'Montserrat Regular';
  font-size: 21px;
  margin-bottom: 1rem;
  text-align: center;
  margin-left: 0;
  @media (min-width: 992px) {
    margin-left: 23px;
    text-align: left;
    display: inline-block;
  }
  @media (min-width: 1200px) {
    font-size: 26px;
  }
`;

export default class TutorialModal extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    const { text } = this.props;

    return (
      <TutorialContainer>
        <TutorialLink onClick={this.openModal}>
          <TutorialButton src='/images/btn-tutorial.png' alt='' />
        </TutorialLink>
        <TutorialText>{text}</TutorialText>

        <ModalVideo
          channel='vimeo'
          isOpen={this.state.isOpen}
          videoId='41710130'
          onClose={() => this.setState({ isOpen: false })}
          autoplay={true}
        />
      </TutorialContainer>
    );
  }
}
