import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer, { LastSection } from '../../components/Footer';
import PageNavigator from '../../components/PageNavigator';
import Option from '../../components/Option';
import Recommendation from '../../components/Recommendation';
import TutorialButton from '../../components/TutorialButton';

const Column = styled(Col)`
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: ${(props) => (props.borderright ? `1px solid #D8D8D8` : null)};
  border-right: none;
  @media (min-width: 768px) {
    text-align: left;
    border-right: ${(props) =>
      props.borderright ? `1px solid #D8D8D8` : null};
    border-bottom: none;
  }
`;

const RowContainer = styled(Row)`
  justify-content: center;
`;

export default ({
  selectorChangeHandler,
  selector,
  recommended,
  isLoading,
}) => (
  <>
    <Container>
      <Header
        subtitle="orthotic selector | question five"
        title="Lateral malleloar (ankle) curvature"
      />
    </Container>
    <LastSection>
      <Container>
        <TutorialButton text="– Need help with this step? Watch our handy guide for tips & advice." />
        <RowContainer>
          <Column lg="8" md="6" className="mb-5" borderright="true">
            <Option
              img="/images/lateralcurvature/lateralcurvature1.png"
              title="Option One.<br/>Pronated"
              text="Ankles lean in (Inside Lean)"
              btn="pronated"
              selectorChangeHandler={selectorChangeHandler}
              selector={selector}
              type="lateralCurvature"
            />
            <Option
              img="/images/lateralcurvature/lateralcurvature2.png"
              title="Option Two.<br/>Neutral"
              text="No lean"
              btn="neutral"
              selectorChangeHandler={selectorChangeHandler}
              selector={selector}
              type="lateralCurvature"
            />
            <Option
              img="/images/lateralcurvature/lateralcurvature3.png"
              title="Option Three.<br/>Supinated"
              text="Ankles lean out (Outside Lean)"
              btn="supinated"
              selectorChangeHandler={selectorChangeHandler}
              selector={selector}
              type="lateralCurvature"
            />
            <PageNavigator
              prevURL="/navicularbulging"
              nextURL={selector.lateralCurvature ? '/talarheadpalpation' : ''}
            />
          </Column>
          <Column lg="4" md="6" className="mb-5 text-center">
            <Recommendation isLoading={isLoading} recommended={recommended} />
          </Column>
        </RowContainer>
      </Container>
      <Footer margintop="2" progressBar={6} />
    </LastSection>
  </>
);