export const body_weight = [
  { value: '20 - 50kg', label: '20 - 50kg', eva_density: 270, shell_thickness: 3 },
  { value: '50 - 80kg', label: '50 - 80kg', eva_density: 270, shell_thickness: 3.5 },
  { value: '80 - 100kg', label: '80 - 100kg', eva_density: 300, shell_thickness: 4 },
  { value: '100 - 120kg', label: '100 - 120kg', eva_density: 350, shell_thickness: 4.5 },
  { value: '120 - 150kg', label: '120 - 150kg', eva_density: 400, shell_thickness: 5 },
];

export const shoe_size = [
  {
    label: "US Mens Size",
    options: [
      { label: "6 - US Mens", value: "6 (US Mens Size)", shell_length: 148.166, top_cover: 245, arch_height: 19, shell_width: 67.568 },
      { label: "6.5 - US Mens", value: "6.5 (US Mens Size)", shell_length: 153.457, top_cover: 260, arch_height: 20, shell_width: 69.701 },
      { label: "7 - US Mens", value: "7 (US Mens Size)", shell_length: 164.041, top_cover: 265, arch_height: 20, shell_width: 70.488 },
      { label: "7.5 - US Mens", value: "7.5 (US Mens Size)", shell_length: 166.688, top_cover: 270, arch_height: 20, shell_width: 72.075 },
      { label: "8 - US Mens", value: "8 (US Mens Size)", shell_length: 169.532, top_cover: 275, arch_height: 20, shell_width: 73.663 },
      { label: "8.5 - US Mens", value: "8.5 (US Mens Size)", shell_length: 171.979, top_cover: 275, arch_height: 20, shell_width: 75.25 },
      { label: "9 - US Mens", value: "9 (US Mens Size)", shell_length: 174.625, top_cover: 280, arch_height: 20, shell_width: 76.838 },
      { label: "9.5 - US Mens", value: "9.5 (US Mens Size)", shell_length: 177.271, top_cover: 280, arch_height: 21, shell_width: 78.423 },
      { label: "10 - US Mens", value: "10 (US Mens Size)", shell_length: 179.916, top_cover: 285, arch_height: 21, shell_width: 80.013 },
      { label: "10.5 - US Mens", value: "10.5 (US Mens Size)", shell_length: 182.523, top_cover: 285, arch_height: 21, shell_width: 81.6 },
      { label: "11 - US Mens", value: "11 (US Mens Size)", shell_length: 185.207, top_cover: 290, arch_height: 22, shell_width: 83.188 },
      { label: "11.5 - US Mens", value: "11.5 (US Mens Size)", shell_length: 187.853, top_cover: 295, arch_height: 22, shell_width: 84.775 },
      { label: "12 - US Mens", value: "12 (US Mens Size)", shell_length: 190.5, top_cover: 305, arch_height: 22, shell_width: 86.363 },
      { label: "12.5 - US Mens", value: "12.5 (US Mens Size)", shell_length: 193.147, top_cover: 310, arch_height: 22, shell_width: 87.95 },
      { label: "13 - US Mens", value: "13 (US Mens Size)", shell_length: 195.791, top_cover: 315, arch_height: 22, shell_width: 89.538 },
      { label: "13.5 - US Mens", value: "13.5 (US Mens Size)", shell_length: 198.438, top_cover: 315, arch_height: 22, shell_width: 91.125 },
      { label: "14 - US Mens", value: "14 (US Mens Size)", shell_length: 201.082, top_cover: 320, arch_height: 22, shell_width: 92.713 },
    ]
  },
  {
    label: "US Womens Size",
    options: [
      { label: "3 - US Womens", value: "3 (US Womens Size)", shell_length: 132.291, top_cover: 230, arch_height: 18, shell_width: 61.167 },
      { label: "3.5 - US Womens", value: "3.5 (US Womens Size)", shell_length: 134.938, top_cover: 235, arch_height: 18, shell_width: 62.234 },
      { label: "4 - US Womens", value: "4 (US Womens Size)", shell_length: 137.852, top_cover: 240, arch_height: 18, shell_width: 63.301 },
      { label: "4.5 - US Womens", value: "4.5 (US Womens Size)", shell_length: 140.228, top_cover: 240, arch_height: 18, shell_width: 64.367 },
      { label: "5 - US Womens", value: "5 (US Womens Size)", shell_length: 142.875, top_cover: 245, arch_height: 19, shell_width: 65.434 },
      { label: "5.5 - US Womens", value: "5.5 (US Womens Size)", shell_length: 145.522, top_cover: 245, arch_height: 19, shell_width: 66.501 },
      { label: "6 - US Womens", value: "6 (US Womens Size)", shell_length: 148.166, top_cover: 250, arch_height: 19, shell_width: 67.568 },
      { label: "6.5 - US Womens", value: "6.5 (US Womens Size)", shell_length: 150.813, top_cover: 255, arch_height: 19, shell_width: 68.635 },
      { label: "7 - US Womens", value: "7 (US Womens Size)", shell_length: 153.457, top_cover: 260, arch_height: 20, shell_width: 69.701 },
      { label: "7.5 - US Womens", value: "7.5 (US Womens Size)", shell_length: 156.103, top_cover: 265, arch_height: 20, shell_width: 70.76 },
      { label: "8 - US Womens", value: "8 (US Womens Size)", shell_length: 158.75, top_cover: 270, arch_height: 20, shell_width: 71.835 },
      { label: "8.5 - US Womens", value: "8.5 (US Womens Size)", shell_length: 161.397, top_cover: 270, arch_height: 20, shell_width: 72.792 },
      { label: "9 - US Womens", value: "9 (US Womens Size)", shell_length: 164.041, top_cover: 275, arch_height: 20, shell_width: 73.969 },
    ]
  },
  {
    label: "US Kids Size",
    options: [
      { label: "7 - US Kids", value: "7 (US Kids Size)", shell_length: 100.541, top_cover: 175, arch_height: 15, shell_width: 50.488 },
      { label: "7.5 - US Kids", value: "7.5 (US Kids Size)", shell_length: 103.188, top_cover: 180, arch_height: 15, shell_width: 51.288 },
      { label: "8 - US Kids", value: "8 (US Kids Size)", shell_length: 105.832, top_cover: 185, arch_height: 15, shell_width: 52.088 },
      { label: "8.5 - US Kids", value: "8.5 (US Kids Size)", shell_length: 8.478, top_cover: 190, arch_height: 15, shell_width: 52.888 },
      { label: "9 - US Kids", value: "9 (US Kids Size)", shell_length: 111.125, top_cover: 195, arch_height: 15, shell_width: 53.688 },
      { label: "9.5 - US Kids", value: "9.5 (US Kids Size)", shell_length: 113.771, top_cover: 195, arch_height: 15, shell_width: 54.488 },
      { label: "10 - US Kids", value: "10 (US Kids Size)", shell_length: 116.416, top_cover: 200, arch_height: 15, shell_width: 55.288 },
      { label: "10.5 - US Kids", value: "10.5 (US Kids Size)", shell_length: 119.063, top_cover: 205, arch_height: 16, shell_width: 56.088 },
      { label: "11 - US Kids", value: "11 (US Kids Size)", shell_length: 121.707, top_cover: 210, arch_height: 16, shell_width: 56.888 },
      { label: "11.5 - US Kids", value: "11.5 (US Kids Size)", shell_length: 124.353, top_cover: 210, arch_height: 16, shell_width: 57.688 },
      { label: "12 - US Kids", value: "12 (US Kids Size)", shell_length: 127, top_cover: 215, arch_height: 16, shell_width: 58.488 },
      { label: "12.5 - US Kids", value: "12.5 (US Kids Size)", shell_length: 129.647, top_cover: 220, arch_height: 16, shell_width: 59.288 },
      { label: "13 - US Kids", value: "13 (US Kids Size)", shell_length: 132.291, top_cover: 225, arch_height: 17, shell_width: 60.088 },
      { label: "13.5 - US Kids", value: "13.5 (US Kids Size)", shell_length: 134.938, top_cover: 225, arch_height: 17, shell_width: 60.888 },
      { label: "1 - US Kids", value: "1 (US Kids Size)", shell_length: 137.582, top_cover: 230, arch_height: 17, shell_width: 61.688 },
      { label: "1.5 - US Kids", value: "1.5 (US Kids Size)", shell_length: 140.228, top_cover: 235, arch_height: 17, shell_width: 62.488 },
      { label: "2 - US Kids", value: "2 (US Kids Size)", shell_length: 142.875, top_cover: 240, arch_height: 17, shell_width: 63.288 },
      { label: "2.5 - US Kids", value: "2.5 (US Kids Size)", shell_length: 145.54, top_cover: 240, arch_height: 17, shell_width: 64.088 },
      { label: "3 - US Kids", value: "3 (US Kids Size)", shell_length: 148.166, top_cover: 245, arch_height: 17, shell_width: 64.888 },
      { label: "3.5 - US Kids", value: "3.5 (US Kids Size)", shell_length: 150.813, top_cover: 250, arch_height: 17, shell_width: 65.688 },
      { label: "4 - US Kids", value: "4 (US Kids Size)", shell_length: 153.457, top_cover: 255, arch_height: 17, shell_width: 66.488 },
      { label: "4.5 - US Kids", value: "4.5 (US Kids Size)", shell_length: 156.103, top_cover: 260, arch_height: 17, shell_width: 67.288 },
      { label: "5 - US Kids", value: "5 (US Kids Size)", shell_length: 158.75, top_cover: 260, arch_height: 17, shell_width: 68.088 },
    ]
  },
];

export const shoe_type = [
  { value: 'Joggers', label: 'Joggers', device_width: '1- 5 Metatarsal' },
  { value: 'Mens Dress', label: 'Mens Dress', device_width: '1- 4 Metatarsal' },
  { value: 'Womens Dress', label: 'Womens Dress', device_width: '1- 4 Metatarsal' },
  { value: 'Womens Court shoe', label: 'Womens Court shoe', device_width: '1- 4 Metatarsal' },
  { value: 'Womens Flats', label: 'Womens Flats', device_width: '1- 4 Metatarsal' },
  { value: 'Women Heels', label: 'Women Heels', device_width: '1- 4 Metatarsal'  },
  { value: 'Oxford', label: 'Oxford', device_width: '1- 5 Metatarsal' },
];

export const base_device = {
  gaitPlateInToe: 'Gait Plate In-Toe',
  gaitPlateOutToe: 'Gait Plate Out-Toe',
  hook: 'Hook',
  kids: 'Childs Foot',
  lowProfile: 'Low Profile',
  narrowFitting: 'Narrow Fitting',
  moodRootShell: 'Mod Root Shell',
  flangeSkieveHeel: 'Medial Flange + Skieve + Heel cup',
  flangeSkieve: 'Medial Flange + Skieve',
  skieve: 'Medial Skieve',
  unknown: 'Base Shell',
};

export const footTypeList = [{
  id: 1,
  value: 'flat arch',
  img: '/images/foottype/foottype1.png',
  title: 'Option Three.<br/>Flat Foot (Pes Planus)',
  text: '<20mm height of the arch in the middle',
}, {
  id: 2,
  value: 'neutral arch',
  img: '/images/foottype/foottype2.png',
  title: 'Option Three.<br/>Neutral Foot',
  text: '25mm height of the arch in the middle',
}, {
  id: 3,
  value: 'high arch',
  img: '/images/foottype/foottype3.png',
  title: 'Option Three.<br/>High Arch Foot (Carvus)',
  text: '>27mm height of the arch in the middle',
}];

export const toeDirectionList = [
  {
    id: 1,
    value: 'out-toe',
  },
  {
    id: 2,
    value: 'neutral',
  },
  {
    id: 3,
    value: 'in-toe',
  }
]

export const rearViewList = [
  {
    id: 1,
    value: 'everted',
  },
  {
    id: 2,
    value: 'neutral',
  },
  {
    id: 3,
    value: 'inverted',
  }
]

export const navicularBulgingList = [
  {
    id: 1,
    value: 'everted',
  },
  {
    id: 2,
    value: 'moderate',
  },
  {
    id: 3,
    value: 'neutral',
  }
]

export const lateralCurvatureList = [
  {
    id: 1,
    value: 'pronated',
  },
  {
    id: 2,
    value: 'neutral',
  },
  {
    id: 3,
    value: 'supinated',
  }
]

export const talarheadPalpationList = [
  {
    id: 1,
    value: 'outside',
  },
  {
    id: 2,
    value: 'equal',
  },
  {
    id: 3,
    value: 'inside',
  }
]

export const kneePositionList = [
  {
    id: 1,
    value: 'knocked',
  },
  {
    id: 2,
    value: 'straight',
  },
  {
    id: 3,
    value: 'bow knee',
  }
]