import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import Header from '../../components/Header';
import Footer, { LastSection } from '../../components/Footer';
import PageNavigator from '../../components/PageNavigator';
import Recommendation from '../../components/Recommendation';
import TutorialButton from '../../components/TutorialButton';
import { StyledSelector, StyledInput } from '../../config/styles';
import { shoe_size, shoe_type, body_weight } from '../../config/data';

const Column = styled(Col)`
  padding-bottom: 20px;
  text-align: center;
  border-bottom: ${(props) => (props.borderright ? `1px solid #D8D8D8` : null)};
  border-right: none;
  @media (min-width: 768px) {
    text-align: left;
    border-right: ${(props) =>
      props.borderright ? `1px solid #D8D8D8` : null};
    border-bottom: none;
  }
`;

const RowContainer = styled(Row)`
  justify-content: center;
`;

const QuestionTitle = styled.h2`
  font-family: 'Montserrat Bold';
  text-align: left;
  font-size: 21px;
  line-height: 30px;
  color: ${(props) => props.theme.gray};
`;

const QuestionDesc = styled.p`
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.gray};
`;

const QuestionInlineDesc = styled(QuestionDesc)`
  display: inline-block;
`;

const QuestionContainer = styled.div`
  margin-bottom: 2.5rem;
`;

const DownloadContainer = styled.div`
  display: inline-block;
  margin-bottom: 5px;
  span {
    color: ${(props) => props.theme.gray};
  }
  a {
    font-size: 16px;
    font-family: 'Montserrat Bold';
    letter-spacing: 0.5px;
    cursor: pointer;
    color: ${(props) => props.theme.skyBlue};
    transition: all 0.2s ease-in-out;
    display: inline;
    img {
      height: 22px;
      margin-right: 7px;
      filter: brightness(0.8);
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      img {
        filter: none;
      }
    }
  }
`;

export default ({
  selectorChangeHandler,
  selector,
  recommended,
  isLoading,
  setSelector,
}) => {
  const onSelectBodyWeight = (e) => {
    setSelector({
      ...selector,
      bodyWeight: e.value,
      evaDensity: e.eva_density,
      shellThickness: e.shell_thickness
    });
  }

  const onSelectShoeType = (e) => {
    setSelector({
      ...selector,
      shoeType: e.value,
      deviceWidth: e.device_width,
    });
  }

  const onSelectShoeSize = (e) => {
    setSelector({
      ...selector,
      shoeSize: e.value,
      shellLength: e.shell_length,
      shellWidth: e.shell_width,
      archHeight: e.arch_height,
      topCover: String(e.top_cover)
    })
  }

  const filledData = () => {
    if(selector.bodyWeight && selector.shoeType &&
    selector.leftFootLP && selector.leftFootWP &&
    selector.rightFootLP && selector.rightFootWP) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Container>
        <Header
          subtitle='orthotic selector | Question twelve'
          title='Add your sizing information'
        />
      </Container>
      <LastSection>
        <Container>
          <TutorialButton text='– Need help with this step? Watch our handy guide for tips & advice.' />
          <RowContainer>
            <Column lg='8' md='7' className='mb-5' borderright='true'>
              <QuestionContainer>
                <QuestionTitle>A. Your Body Weight</QuestionTitle>
                <QuestionDesc>
                  Body weight will determine Shell thickness or EVA Density
                  <br />
                  (Depending on the style of Orthotic Poly or EVA)
                </QuestionDesc>
                <StyledSelector
                  options={body_weight}
                  placeholder='Select body weight - kg'
                  onChange={(e) => onSelectBodyWeight(e)}
                  value={selector['bodyWeight']}
                />
              </QuestionContainer>

              <QuestionContainer>
                <QuestionTitle>
                  B. Your Shoe Size{' '}
                  <QuestionInlineDesc>
                    – If unsure, add your measurements below
                  </QuestionInlineDesc>
                </QuestionTitle>
                <StyledSelector
                  options={shoe_size}
                  placeholder='Select shoe size (W/M - US)'
                  onChange={(e) => onSelectShoeSize(e)}
                  value={selector['shoeSize']}
                />
              </QuestionContainer>

              <QuestionContainer>
                <QuestionTitle>C. Your Shoe Type</QuestionTitle>
                <QuestionDesc>
                  Please choose <b>one</b> style only for the orthotic device
                </QuestionDesc>
                <StyledSelector
                  options={shoe_type}
                  placeholder='Select type of shoe for insert'
                  onChange={(e) => onSelectShoeType(e)}
                  value={selector['shoeType']}
                />
              </QuestionContainer>

              <QuestionContainer>
                <Row>
                  <Column lg='6' className='text-center'>
                    <QuestionTitle className='mb-4'>
                      D. Left Foot Measurements (CM)
                    </QuestionTitle>
                    <StyledInput
                      type='text'
                      placeholder='Longest Point (back heel to toe end)'
                      className='mb-2'
                      onChange={(e) =>
                        selectorChangeHandler('leftFootLP', e.target.value)
                      }
                      value={selector['leftFootLP']}
                    />
                    <StyledInput
                      type='text'
                      placeholder='Widest Point (across foot)'
                      className='mb-2'
                      onChange={(e) =>
                        selectorChangeHandler('leftFootWP', e.target.value)
                      }
                      value={selector['leftFootWP']}
                    />
                  </Column>
                  <Column lg='6' className='text-center'>
                    <QuestionTitle className='mb-4'>
                      E. Right Foot Measurements (CM)
                    </QuestionTitle>
                    <StyledInput
                      type='text'
                      placeholder='Longest Point (back heel to toe end)'
                      className='mb-2'
                      onChange={(e) =>
                        selectorChangeHandler('rightFootLP', e.target.value)
                      }
                      value={selector['rightFootLP']}
                    />
                    <StyledInput
                      type='text'
                      placeholder='Widest Point (across foot)'
                      className='mb-2'
                      onChange={(e) =>
                        selectorChangeHandler('rightFootWP', e.target.value)
                      }
                      value={selector['rightFootWP']}
                    />
                  </Column>
                </Row>
              </QuestionContainer>
              <DownloadContainer>
                <a href="/images/pdf-download/PodStep-Size-guide-download.pdf" download>
                  <img src='/images/download-icon.png' alt='' />
                  Download our PDF printout guide
                </a>
                <span> to help you measure your feet.</span>
              </DownloadContainer>
              <p className='mb-6'>
                You will need access to a printer. Turn off the “Fit to page”
                option in your printer settings.
              </p>
              <PageNavigator
                prevURL='/footpain'
                nextURL={filledData()  ? '/orthoticprescription' : ''}
              />
            </Column>
            <Column lg='4' md='5' className='mb-5 text-center'>
              <Recommendation isLoading={isLoading} recommended={recommended} />
            </Column>
          </RowContainer>
        </Container>
        <Footer margintop="5" progressBar={12} />
      </LastSection>
    </>
  );
}