import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LateralViewOne } from '../config/dorsal-in/dorsal-in-1.svg';
import { ReactComponent as LateralViewTwo } from '../config/dorsal-in/dorsal-in-2.svg';
import { ReactComponent as LateralViewThree } from '../config/dorsal-in/dorsal-in-3.svg';
import { ReactComponent as LateralViewFour } from '../config/dorsal-in/dorsal-in-4.svg';
import { ReactComponent as LateralViewFive } from '../config/dorsal-in/dorsal-in-5.svg';
import { ReactComponent as LateralViewSix } from '../config/dorsal-in/dorsal-in-6.svg';
import { ReactComponent as LateralViewSeven } from '../config/dorsal-in/dorsal-in-7.svg';
import { ReactComponent as LateralViewEight } from '../config/dorsal-in/dorsal-in-8.svg';
import { find } from 'lodash';

export default ({ footPainLateral, onSelectPain, viewOnly }) => {

  return (
    <OptionImg viewonly={viewOnly}>
      <LateralView1
        onClick={() => !viewOnly && onSelectPain(1)}
        className={find(footPainLateral, { regionNo: 1 }) && 'active'}
      />
      <LateralView2
        onClick={() => !viewOnly && onSelectPain(2)}
        className={find(footPainLateral, { regionNo: 2 }) && 'active'}
      />
      <LateralView3
        onClick={() => !viewOnly && onSelectPain(3)}
        className={find(footPainLateral, { regionNo: 3 }) && 'active'}
      />
      <LateralView4
        onClick={() => !viewOnly && onSelectPain(4)}
        className={find(footPainLateral, { regionNo: 4 }) && 'active'}
      />
      <LateralView5
        onClick={() => !viewOnly && onSelectPain(5)}
        className={find(footPainLateral, { regionNo: 5 }) && 'active'}
      />
      <LateralView6
        onClick={() => !viewOnly && onSelectPain(6)}
        className={find(footPainLateral, { regionNo: 6 }) && 'active'}
      />
      <LateralView7
        onClick={() => !viewOnly && onSelectPain(7)}
        className={find(footPainLateral, { regionNo: 7 }) && 'active'}
      />
      <LateralView8
        onClick={() => !viewOnly && onSelectPain(8)}
        className={find(footPainLateral, { regionNo: 8 }) && 'active'}
      />
    </OptionImg>
  )
}

const LateralView1 = styled(LateralViewOne)`
  top: 7px;
  left: 24px;
`;
const LateralView2 = styled(LateralViewTwo)`
  top: 117px;
  left: 24px;
  z-index: 3;
`;
const LateralView3 = styled(LateralViewThree)`
  top: 118px;
  left: 46px;
  z-index: 2;
`;
const LateralView4 = styled(LateralViewFour)`
  top: 194px;
  left: 14px;
`;
const LateralView5 = styled(LateralViewFive)`
  top: 168px;
  left: 72px;
  z-index: 1;
`;
const LateralView6 = styled(LateralViewSix)`
  top: 166px;
  left: 111px;
`;
const LateralView7 = styled(LateralViewSeven)`
  top: 194px;
  left: 178px;
`;
const LateralView8 = styled(LateralViewEight)`
  top: 230px;
  left: 225px;
`;

const OptionImg = styled.div`
  background: url(/images/dorsal-in.png);
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-color: #ffffff;
  position: relative;
  height: 300px;
  width: 320px;
  margin-bottom: 35px;
  margin-top: 25px;
  box-shadow: 0 2px 36px 0 rgba(1, 28, 47, 0.1);
  svg {
    transform: scale(0.9);
    position: absolute;
    &:hover {
      path {
        fill: ${props => props.viewonly ? 'unset' : 'rgba(0, 0, 0, 0.1)'};
      }
    }
    &.active {
      path {
        fill: #0a96b7cf;
        &.label-svg {
          fill: #fec192;
        }
      }
    }
    path {
      cursor: ${props => props.viewonly ? 'default' : 'pointer'};
      transition: all 0.2s ease-in-out;
      fill: transparent;
      &.label-svg {
        fill: #0a96b7cf;
      }
    }
  }
  @media (max-width: 767px) {
    margin: auto;
    margin-bottom: 35px;
    margin-top: 25px;
  }
`;