import React, { useRef, useEffect } from 'react';
import { SkyBlueTag, StyledInput } from '../config/styles';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import ImageMapper from 'react-image-mapper';
import { ReactComponent as PinMark } from '../config/pin-mark.svg';
import { theme } from '../config/constants';
import { isEmpty } from 'lodash';

export default ({ selectorChangeHandler, selector, allowEdit }) => {
  const imgLeft = selector.leftFootWatermark || '/images/foot-left.png';
  const imgRight = selector.rightFootWatermark || '/images/foot-right.png';
  const { structuralAbnormalities } = selector;
  const { leftFootIssues, rightFootIssues } = structuralAbnormalities;
  const leftDropDownRef = useRef();
  const rightDropDownRef = useRef();

  const handleClickOutside = (e) => {
    if(!leftDropDownRef.current.contains(e.target) &&
    !rightDropDownRef.current.contains(e.target)) {
      clearDropDowns();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const updateLeftFoot = (array) => {
    if(selectorChangeHandler) {
      selectorChangeHandler('structuralAbnormalities', {
        ...selector['structuralAbnormalities'],
        leftFootIssues: array,
      })
    }
  }

  const updateRightFoot = (array) => {
    if(selectorChangeHandler) {
      selectorChangeHandler('structuralAbnormalities', {
        ...selector['structuralAbnormalities'],
        rightFootIssues: array,
      })
    }
  }

  const clearDropDowns = () => {
    const updatedLeftFoot = leftFootIssues.filter((item) => {
      if(item.name) return item;
      return false;
    })
    const updatedRightFoot = rightFootIssues.filter((item) => {
      if(item.name) return item;
      return false;
    })

    if(selectorChangeHandler){
      selectorChangeHandler('structuralAbnormalities', {
        ...selector['structuralAbnormalities'],
        rightFootIssues: updatedRightFoot,
        leftFootIssues: updatedLeftFoot,
      })
    }
  }

  const onClickSpot = (event, footSide) => {
    if(allowEdit) {
      clearDropDowns();
      let updatedFootIssues = []
      if(footSide === 'left') {
        updatedFootIssues = leftFootIssues.filter((item) => {
          if(item.name) return item;
          return false;
        })
      } else {
        updatedFootIssues = rightFootIssues.filter((item) => {
          if(item.name) return item;
          return false;
        })
      }
      const coords = { x: event.nativeEvent.layerX, y: event.nativeEvent.layerY };
      updatedFootIssues.push({ id: Date.now(), coords });

      if(footSide === 'left') {
        updateLeftFoot(updatedFootIssues)
      } else {
        updateRightFoot(updatedFootIssues)
      }
    }
  }

  const onSelectIssue = (e, data, footSide) => {
    let footIssues = []
    if(footSide === 'left') {
      footIssues = leftFootIssues;
    } else {
      footIssues = rightFootIssues;
    }

    const updatedFootIssues = footIssues.map((item) => {
      if(data.id === item.id) {
        let updatedItem = {
          ...data,
          name: e.target.id
        }
        return updatedItem;
      }
      return item;
    })

    if(footSide === 'left') {
      updateLeftFoot(updatedFootIssues)
    } else {
      updateRightFoot(updatedFootIssues)
    }
  }

  const deleteIssue = (id, footSide) => {
    let updatedIssues = []
    if(footSide === 'left') {
      updatedIssues = leftFootIssues.filter((item) => {
        if(id === item.id) return false;
        return item;
      })
    } else {
      updatedIssues = rightFootIssues.filter((item) => {
        if(id === item.id) return false;
        return item;
      })
    }

    if(footSide === 'left') {
      updateLeftFoot(updatedIssues)
    } else {
      updateRightFoot(updatedIssues)
    }
  }


  return (
    <FootRow>
      <FootColumn xs='6'>
        <FootWatermarkContainer ref={leftDropDownRef} allowEdit={allowEdit}>
          <ImageMapper
            src={imgLeft}
            onImageClick={event => onClickSpot(event, 'left')}
          />
          <FootLabel className="footlabel">Left Foot</FootLabel>
          {
            !isEmpty(leftFootIssues) && leftFootIssues.map((data, ndx) => (
              <div key={`pin-${ndx}`}>
                <Pin data={data.coords}/>
                { data.name && (
                    <IssueLabel data={data.coords}>
                      <span>
                        <p>{data.name}</p>
                        {
                          allowEdit && (
                            <span
                              className='fa fa-trash-o'
                              onClick={() => deleteIssue(data.id, 'left')}
                            />
                          )
                        }
                      </span>
                    </IssueLabel>
                  )
                }
                {
                  !data.name &&
                  (
                    <IssueOptions data={data.coords}>
                      <SkyBlueTag id="bunions" onClick={(e) => onSelectIssue(e, data, 'left')}>Bunions</SkyBlueTag>
                      <SkyBlueTag id="nodules" onClick={(e) => onSelectIssue(e, data, 'left')}>Nodules</SkyBlueTag>
                      <SkyBlueTag id="amputations" onClick={(e) => onSelectIssue(e, data, 'left')}>Amputations</SkyBlueTag>
                      <SkyBlueTag id="hammer-toes" onClick={(e) => onSelectIssue(e, data, 'left')}>Hammer Toes</SkyBlueTag>
                      <SkyBlueTag id="corns" onClick={(e) => onSelectIssue(e, data, 'left')}>Corns</SkyBlueTag>
                      <SkyBlueTag id="calluses" onClick={(e) => onSelectIssue(e, data, 'left')}>Calluses</SkyBlueTag>
                      <SkyBlueTag id="ulcerations" onClick={(e) => onSelectIssue(e, data, 'left')}>Ulcerations</SkyBlueTag>
                      <SkyBlueTag id="warts" onClick={(e) => onSelectIssue(e, data, 'left')}>Warts</SkyBlueTag>
                    </IssueOptions>
                  )
                }
              </div>
            ))
          }
        </FootWatermarkContainer>
      </FootColumn>

      <FootColumn xs='6'>
        <FootWatermarkContainer ref={rightDropDownRef} allowEdit={allowEdit}>
          <ImageMapper
            src={imgRight}
            onImageClick={event => onClickSpot(event, 'right')}
            allowEdit={allowEdit}
          />
          <FootLabel className="footlabel">Right Foot</FootLabel>
          {
            !isEmpty(rightFootIssues) && rightFootIssues.map((data, ndx) => (
              <div key={`pin-${ndx}`}>
                <Pin data={data.coords} />
                { data.name && (
                    <IssueLabel data={data.coords}>
                      <span>
                        <p>{data.name}</p>
                        {
                          allowEdit && (
                            <span
                              className='fa fa-trash-o'
                              onClick={() => deleteIssue(data.id, 'right')}
                            />
                          )
                        }
                      </span>
                    </IssueLabel>
                  )
                }
                {
                  !data.name &&
                  (
                    <IssueOptions data={data.coords}>
                      <SkyBlueTag id="bunions" onClick={(e) => onSelectIssue(e, data, 'right')}>Bunions</SkyBlueTag>
                      <SkyBlueTag id="nodules" onClick={(e) => onSelectIssue(e, data, 'right')}>Nodules</SkyBlueTag>
                      <SkyBlueTag id="amputations" onClick={(e) => onSelectIssue(e, data, 'right')}>Amputations</SkyBlueTag>
                      <SkyBlueTag id="hammer-toes" onClick={(e) => onSelectIssue(e, data, 'right')}>Hammer Toes</SkyBlueTag>
                      <SkyBlueTag id="corns" onClick={(e) => onSelectIssue(e, data, 'right')}>Corns</SkyBlueTag>
                      <SkyBlueTag id="calluses" onClick={(e) => onSelectIssue(e, data, 'right')}>Calluses</SkyBlueTag>
                      <SkyBlueTag id="ulcerations" onClick={(e) => onSelectIssue(e, data, 'right')}>Ulcerations</SkyBlueTag>
                      <SkyBlueTag id="warts" onClick={(e) => onSelectIssue(e, data, 'right')}>Warts</SkyBlueTag>
                    </IssueOptions>
                  )
                }
              </div>
            ))
          }
        </FootWatermarkContainer>
      </FootColumn>

      <>
        <CheckboxContainer>
          <p>
            If you have a leg length difference please tell us which leg and by how much
          </p>
          {
            allowEdit
              ? (
                <StyledInput
                  onChange={(e) =>
                    selectorChangeHandler('structuralAbnormalities', {
                      ...selector['structuralAbnormalities'],
                      legLengthDifference: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Leg length difference"
                  className="mb-2"
                  value={selector.structuralAbnormalities.legLengthDifference}
                />
              ) : <p>{selector.structuralAbnormalities.legLengthDifference}</p>
          }

        </CheckboxContainer>
      </>
    </FootRow>
  )
}

const Column = styled(Col)`
  @media (max-width: 420px) {
    flex: unset !important;
    max-width: 100% !important;
    margin-bottom: 30px;
  }
`;

const Pin = styled(PinMark)`
  position: absolute;
  top: ${props => props.data.y}px;
  left: ${props => props.data.x}px;
  z-index: 3;

  &:hover + label {
    display: flex;
  }
`;

const IssueLabel = styled.label`
  display: none;
  position: absolute;
  top: ${props => props.data.y - 6}px;
  left: ${props => props.data.x + 22}px;
  z-index: 4;
  padding-left: 8px;
  margin: 0;
  text-align: center;
  max-width: 150px;
  width: 100%;

  &:hover {
    display: block;
  }

  span {
    background: ${theme.darkBlue};
    text-transform: uppercase;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 35px;
    width: 100%;

    p {
      font-size: 14px;
      color: white;
      margin: 0;
      flex: 2;
    }

    span {
      flex: 0.15;
      color: white;
      font-size: 16px;
      position: relative;
      bottom: 1px;
      cursor: pointer;
      padding: 0;
      padding-left: 8px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

const IssueOptions = styled.div`
  position: absolute;
  top: ${props => props.data.y - 7}px;
  left: ${props => props.data.x + 25}px;
  z-index: 3;

  span {
    cursor: pointer;
    width: 150px;
    margin: 5px;
  }
`;

const FootRow = styled(Row)`
  margin-left: 0 !important;
  margin-right: 0 !important;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const FootColumn = styled(Column)`
  padding: 0 !important;
  height: 400px;
  width: 270px !important;
  max-width: 270px !important;
  @media (max-width: 991px) {
    margin-bottom: 20px !important;
    flex: 1 !important;
  }

  &:first-child {
    @media (min-width: 767px) {
      margin-right: 20px;
    }
  }
`;

const FootWatermarkContainer = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  margin: auto;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 36px 0 rgba(1, 28, 47, 0.1);

  div:first-child {
    cursor: ${props => props.allowEdit ? 'pointer' : 'default'};
    position: relative;
    width: 240px;
    height: 100% !important;
    img {
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100% !important;
      height: 100% !important;
      object-fit: contain !important;
    }
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;

const FootLabel = styled.div`
  color: ${(props) => props.theme.gray};
  font-family: 'Montserrat Bold';
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  text-align: left;
  bottom: 5px;
  right: 10px;
  @media (max-width: 992px) {
    font-size: 16px;
  }

  &.footlabel {
    position: absolute !important;
    width: auto !important;
    height: auto !important;
  }
`;

const CheckboxContainer = styled.div`
  margin-top: 2.5rem;
  margin-bottom: -1rem;
`;