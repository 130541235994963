import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { SkyBlueButton } from '../config/styles';
import LateralPains from '../components/LateralPains';
import { theme } from '../config/constants';
import { find } from 'lodash';
import { LateralDiagnosis, DiagnosisDescriptionList } from '../config/diagnosisData';

export default ({
  title,
  text,
  question,
  selectorChangeHandler,
  selector,
  type,
  viewOnly,
}) => {
  const footPainLateral = selector['footPainLateral'];
  const selectedFootPain = footPainLateral || [];
  const [diagnosisList, setDiagnosisList] = useState();

  const onSelectList = (selected, id) => {
    let footPains = footPainLateral;

    footPains = footPains.map(x => {
      if(x.regionNo === id) {
        return {
          regionNo: id,
          diagnosis: selected,
          description: getDiagnosisDescription(selected),
        }
      }
      return x;
    })

    selectorChangeHandler(type, footPains);
    setDiagnosisList();
  }

  const onSelectPain = (regionNo) => {
    let footPains = footPainLateral;

    if(find(footPains, { regionNo })) {
      setDiagnosisList();
      footPains = footPains.filter(x => {
        if(x.regionNo === regionNo) return false;
        return x;
      });
    } else {
      setDiagnosisList(LateralDiagnosis[regionNo - 1]);
      const footPain = {
        regionNo,
        daignosis: '',
        description: '',
      }
      footPains.push(footPain);
    }

    selectorChangeHandler(type, footPains);
  }

  const getDiagnosisDescription = (name) => {
    let match = find(DiagnosisDescriptionList, { name });
    match = match ? match.description : '';
    return match;
  };

  return (
    <Option>
      <CustomRow>
        <Col>
          <OptionTitle dangerouslySetInnerHTML={{ __html: title }} />
          <LateralPains
            footPainLateral={footPainLateral}
            selector={selector}
            onSelectPain={onSelectPain}
            viewOnly={viewOnly}
          />
        </Col>
        <Col>
          <OptionDesc>
            {
              selectedFootPain.length > 0
                && (
                  <SelectedFootPain>
                    {
                      selectedFootPain.map((footPain, ndx) => {
                        if (!footPain.diagnosis) return null;
                        const onRemoveFootPain = () => onSelectPain(footPain.regionNo);

                        return (
                          <CustomButton
                            small="true"
                            key={`lateral-${ndx}`}
                            className="active mb-3"
                            tooltip={getDiagnosisDescription(footPain.diagnosis)}
                            onClick={onRemoveFootPain}
                          >
                            {footPain.diagnosis}
                          </CustomButton>
                        )
                      })
                    }
                  </SelectedFootPain>
                )
            }
            {
              diagnosisList
                && (
                  <>
                    <OptionQuestion dangerouslySetInnerHTML={{ __html: question }} />
                    <OptionText>{text}</OptionText>
                    {
                      diagnosisList.diagnosis.map((name, ndx) => (
                        <CustomButton
                          small="true"
                          key={`lateral-${ndx}`}
                          className="mb-3"
                          tooltip={getDiagnosisDescription(name)}
                          onClick={() => onSelectList(name, diagnosisList.regionNo)}
                        >
                          {name}
                        </CustomButton>
                      ))
                    }
                  </>
                )
            }
          </OptionDesc>
        </Col>
      </CustomRow>
    </Option>
  );
};

const Option = styled.div`
  margin-bottom: 4rem;
  @media (min-width: 992px) {
    margin-bottom: 0;
    display: flex;
  }
`;

const OptionDesc = styled.div`
  @media (min-width: 768px) {
    padding-right: 15px;
  }
  @media (min-width: 992px) {
    padding-left: 15px;
    padding-right: 25px;
  }
`;

export const OptionTitle = styled.h2`
  font-family: 'Montserrat Bold';
  font-size: 21px;
  line-height: 30px;
  color: ${theme.gray};
`;

export const OptionQuestion = styled.h2`
  margin-bottom: 3.3rem;
  font-family: 'Montserrat Bold';
  font-size: 21px;
  line-height: 30px;
  color: ${theme.gray};
  @media (max-width: 991px) {
    margin-bottom: 1.5rem;
  }
`;

const OptionText = styled.p`
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  color: ${theme.gray};
`;

const CustomButton = styled(SkyBlueButton)`
  position: relative;
  &:hover,
  &.active {
    background-color: ${theme.skyBlue};
    color: #ffffff;
  }

  &:hover {
    &::after {
      ${
        (props) => props.tooltip &&
        `content: "${props.tooltip}";`
      }
      color: white;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 1px;
      font-weight: 400;
      text-transform: none;
      font-family: 'Montserrat Regular';
      position: absolute;
      left: 0;
      right: 0;
      margin-top: 35px;
      background: #4c5b69;
      border-radius: 5px;
      padding: 18px 16px 20px;
      z-index: 1;
    }
  }
`;

const CustomRow = styled(Row)`
  flex: 1;
  @media(max-width: 991px) {
    flex-direction: column;
  }
`;

const SelectedFootPain = styled.div`
  margin-bottom: 3.3rem;
`;
