import axios from 'axios';

export const BASE = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://podstep-api.appspot.com';
// export const BASE = 'https://podstep-api.appspot.com';

export const getProducts = () => axios.get(
  'https://www.podstep.com.au/wp-json/pod/v1/products',
  {
    auth: {
      username: 'ck_f1366926f6756cb135b5636715d123b44a4369fb',
      password: 'cs_af5d699fa844fba872434f129c32cfa243e8eb4b',
    },
  },
);
export const signInSignUp = payload => axios.post(`${BASE}/login`, payload);
export const postOrder = payload => axios.post(`${BASE}/orders`, payload);
export const updateOrder = (id, payload) => axios.post(`${BASE}/orders/${id}`, payload);
export const postPrescription = payload => axios.post(`${BASE}/prescriptions`, payload);
export const postUpload = payload => axios.post(`${BASE}/upload`, payload);
export const getOrder = orderId => axios.get(`${BASE}/orders/${orderId}`);
export const getOrders = params => axios.get(`${BASE}/orders`, { params });
export const getPrescription = id => axios.get(`${BASE}/prescriptions/${id}`);
export const getPrescriptions = () => axios.get(`${BASE}/prescriptions`);
export const getUserPrescriptions = id => axios.get(`${BASE}/prescriptions?user=${id}`);
