import { Router } from '@reach/router';
import React, { useEffect, useState } from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
import { ThemeProvider } from 'styled-components';

// import About from './pages/About';
// import AdminHomepage from './pages/AdminHomepage';
// import Cart from './pages/Cart';
// import FittingInstructions from './pages/FittingInstructions';
// import Homepage from './pages/Homepage';
// import MyAccount from './pages/MyAccount';
// import Navbar from './components/Navbar';
// import Prescription from './pages/Prescription';
// import Privacy from './pages/Privacy';
// import Product from './pages/Product';
// import Order from './pages/Order';
import Selector from './pages/Selector';
// import Terms from './pages/Terms';

import { theme } from './config/constants';

import './css/font-awesome.min.css';
import './css/index.css';
import 'react-input-range/lib/css/index.css';

const options = {
  overlayColor: 'rgb(0, 0, 0, 0.8)',
  captionStyle: {
    captionColor: '#a6cfa5',
    captionFontFamily: 'Raleway, sans-serif',
    captionFontSize: '22px',
    captionFontWeight: '300',
    captionFontStyle: 'capitalize',
  },
  buttonsStyle: {
    buttonsBackgroundColor: '#1b5245',
    buttonsIconColor: 'rgba(126, 172, 139, 0.8)',
  },
  autoplaySpeed: 0,
  transitionSpeed: 500,
  showCaption: false,
  showThumbnails: true,
  enablePanzoom: false,
};

function App() {
  // const [cart, setCart] = useState({});
  const [user, setUser] = useState(null);
  // const [userJson, setUserJson] = useState(null);
  // let hasPrescriptionInCart = false;

  // Object.values(cart || {}).forEach(cartItem => {
  //   if (cartItem.prescription) {
  //     hasPrescriptionInCart = true;
  //   }
  // });

  const addToCart = (product, isCustomFit, prescription) => {
    let cart = {};
    const podstepCart = localStorage.getItem('podstepCart');
    const item = { ...product, custom_fit: isCustomFit || false};

    if (podstepCart) {
      cart = JSON.parse(podstepCart);
    }

    if (!(item.id in cart)) {
      cart[item.id] = item;
    }

    if (!cart[item.id].quantity) {
      cart[item.id].quantity = 0;
    }

    cart[item.id].quantity++;

    if (prescription) {
      cart[item.id].prescription = prescription;
    }

    // updateCart(cart);
  };

  // const reOrder = items => {
  //   items.forEach((item) => {
  //     addToCart(item, item.custom_fit);
  //   })

  //   navigate('/cart');
  // }

  // const updateCart = newCart => {
  //   localStorage.setItem('podstepCart', JSON.stringify(newCart));

  //   setCart(newCart);
  // };

  const updateUser = userId => {
    if (userId) {
      localStorage.setItem('podstepUserId', JSON.stringify(userId));
    } else {
      localStorage.removeItem('podstepUserId');
    }

    setUser(userId);
    // setUserJson(null);
  }

  useEffect(() => {
    // const podstepCart = localStorage.getItem('podstepCart');
    const userId = localStorage.getItem('podstepUserId');

    // if (podstepCart) {
    //   setCart(JSON.parse(podstepCart));
    // }

    if (userId) {
      setUser(userId);

      // try {
      //   setUserJson(JSON.parse(userId));
      // } catch (err) {
      //   console.log('Error parsing user:', err);
      // }
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SimpleReactLightbox {...options}>
        <div className="App">
          {/* <Navbar cart={cart} user={user} isAdmin={userJson && userJson.admin} updateUser={updateUser} /> */}
          <Router>
            {/* <Homepage path="/" isAdmin={userJson && userJson.admin} hasPrescriptionInCart={hasPrescriptionInCart} />
            <About path="about" />
            <Privacy path="privacy-policy" />
            <Terms path="terms-conditions" />
            <Prescription path="prescription/:id" />
            <Prescription path="prescription/:id/:orderId" />
            <Order path="order/:orderId" />
            <Product path="product" addToCart={addToCart} />
            <AdminHomepage path="/admin" user={user} updateUser={updateUser} /> */}
            {/* <Selector path="/" user={user} updateUser={updateUser} addToCart={addToCart}/> */}
            <Selector path="/*" user={user} updateUser={updateUser} addToCart={addToCart}/>
            {/* <FittingInstructions path="fitting-instructions/:id" />
            <FittingInstructions path="fitting-instructions" />
            <MyAccount path="myaccount" user={user} updateUser={updateUser} reOrder={reOrder}/>
            <Cart path="cart/*" cart={cart} updateCart={updateCart} updateUser={updateUser} /> */}
          </Router>
        </div>
      </SimpleReactLightbox>
    </ThemeProvider>
  );
}

export default App;
