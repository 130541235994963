import React from 'react';
import InputRange from 'react-input-range';
import styled from 'styled-components';

const StyledButton = styled.button`
content: '—';
  background-color: transparent;
  border: 0;
  opacity: 0.7;
  outline: none;
  padding: 0;
`;

export default ({
  disabled,
  maxValue,
  minValue,
  onChange,
  step,
  value,
}) => {
  // Default step is 1, if not provided
  const defaultStep = 1;

  const onClickMinus = () => {
    const newValue = value - (step || defaultStep);

    if (newValue >= minValue) {
      onChange(newValue);
    }
  };

  const onClickPlus = () => {
    const newValue = value + (step || defaultStep);

    if (newValue <= maxValue) {
      onChange(newValue);
    }
  };

  return (
    <div>
      <InputRange
        disabled={disabled}
        maxValue={maxValue}
        minValue={minValue}
        onChange={onChange}
        step={step || defaultStep}
        value={value}
      />
      <div className="d-flex justify-content-between align-items-center">
        <StyledButton
          disabled={disabled || value <= minValue}
          onClick={onClickMinus}
          type="button"
        >
          <img alt="icon-minus" src="/images/minus.svg" />
        </StyledButton>
        <StyledButton
          disabled={disabled || value >= maxValue}
          onClick={onClickPlus}
          type="button"
        >
          <img alt="icon-plus" src="/images/plus.svg" />
        </StyledButton>
      </div>
    </div>
  );
};
