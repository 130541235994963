// Themes
export const theme = {
  skyBlue: '#00D0FF',
  darkBlue: '#0B96B8',
  black: '#011C2F',
  gray: '#4C5B69',
  red: '#FF3D60',
  brown: '#54595E',
};

export const AUSTRALIA = 'AUSTRALIA';
export const STRIPE_API_KEY = 'pk_test_51Ht4RFBRs4Ki0AKBGRp1nvDYs2AoXVY1KR035p0LVCAMsE0A2f3dnvkZL71TznYFsOcfDvvLXJkwZPGv3ZLaTa3000pAvPNFb7';
export const STRIPE_AUSTRALIA_SHIPPING_ID = 'price_1HtmNeBRs4Ki0AKBDoxI2SBc';
export const STRIPE_OTHER_SHIPPING_ID = 'price_1HtmNsBRs4Ki0AKBIoY3kQQ2';
export const AUSTRALIA_SHIPPING_PRICE = 22;
export const OTHER_SHIPPING_PRICE = 18;
export const CUSTOM_FIT_PRICE = 375;
export const PERSONALISED_FIT_PRICE = 250;