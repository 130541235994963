import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DorsalViewOne } from '../config/dorsal-out/dorsal-out-1.svg';
import { ReactComponent as DorsalViewTwo } from '../config/dorsal-out/dorsal-out-2.svg';
import { ReactComponent as DorsalViewThree } from '../config/dorsal-out/dorsal-out-3.svg';
import { ReactComponent as DorsalViewFour } from '../config/dorsal-out/dorsal-out-4.svg';
import { ReactComponent as DorsalViewFive } from '../config/dorsal-out/dorsal-out-5.svg';
import { ReactComponent as DorsalViewSix } from '../config/dorsal-out/dorsal-out-6.svg';
import { ReactComponent as DorsalViewSeven } from '../config/dorsal-out/dorsal-out-7.svg';
import { find } from 'lodash';

export default ({ footPainDorsal, onSelectPain, viewOnly }) => {

  return (
    <OptionImg viewonly={viewOnly}>
      <DorsalView1
        onClick={() => !viewOnly && onSelectPain(1)}
        className={find(footPainDorsal, { regionNo: 1 }) && 'active'}
      />
      <DorsalView2
        onClick={() => !viewOnly && onSelectPain(2)}
        className={find(footPainDorsal, { regionNo: 2 }) && 'active'}
      />
      <DorsalView3
        onClick={() => !viewOnly && onSelectPain(3)}
        className={find(footPainDorsal, { regionNo: 3 }) && 'active'}
      />
      <DorsalView4
        onClick={() => !viewOnly && onSelectPain(4)}
        className={find(footPainDorsal, { regionNo: 4 }) && 'active'}
      />
      <DorsalView5
        onClick={() => !viewOnly && onSelectPain(5)}
        className={find(footPainDorsal, { regionNo: 5 }) && 'active'}
      />
      <DorsalView6
        onClick={() => !viewOnly && onSelectPain(6)}
        className={find(footPainDorsal, { regionNo: 6 }) && 'active'}
      />
      <DorsalView7
        onClick={() => !viewOnly && onSelectPain(7)}
        className={find(footPainDorsal, { regionNo: 7 }) && 'active'}
      />
    </OptionImg>
  )
}

const DorsalView1 = styled(DorsalViewOne)`
  top: 7px;
  left: 86px;
`;
const DorsalView2 = styled(DorsalViewTwo)`
  top: 46px;
  left: 40px;
`;
const DorsalView3 = styled(DorsalViewThree)`
  top: 80px;
  left: 100px;
`;
const DorsalView4 = styled(DorsalViewFour)`
  top: 129px;
  left: 45px;
  z-index: 3;
`;
const DorsalView5 = styled(DorsalViewFive)`
  top: 154px;
  left: 104px;
  z-index: 3;
`;
const DorsalView6 = styled(DorsalViewSix)`
  top: 195px;
  left: 85px;
  z-index: 2;
`;
const DorsalView7 = styled(DorsalViewSeven)`
  top: 234px;
  left: 122px;
  z-index: 1;
`;

const OptionImg = styled.div`
  background: url(/images/dorsal-out.png);
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-color: #ffffff;
  position: relative;
  height: 300px;
  width: 320px;
  margin-bottom: 35px;
  margin-top: 25px;
  box-shadow: 0 2px 36px 0 rgba(1, 28, 47, 0.1);
  svg {
    transform: scale(0.94);
    position: absolute;
    &:hover {
      path {
        fill: ${props => props.viewonly ? 'unset' : 'rgba(0, 0, 0, 0.1)'};
      }
    }
    &.active {
      path {
        fill: #0a96b7cf;
        &.label-svg {
          fill: #fec192;
        }
      }
    }
    path {
      cursor: ${props => props.viewonly ? 'default' : 'pointer'};
      transition: all 0.2s ease-in-out;
      fill: transparent;
      &.label-svg {
        fill: #0a96b7cf;
      }
    }
  }
  @media (max-width: 767px) {
    margin: auto;
    margin-bottom: 35px;
    margin-top: 25px;
  }
`;