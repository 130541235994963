import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer, { LastSection } from '../../components/Footer';
import PageNavigator from '../../components/PageNavigator';
import { OptionTitle } from '../../components/Option';
import Recommendation from '../../components/Recommendation';
import { SkyBlueButton, StyledInput } from '../../config/styles';
// import ChatSupport from '../../components/ChatSupport';

const Column = styled(Col)`
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: ${(props) => (props.borderright ? `1px solid #D8D8D8` : null)};
  border-right: none;
  @media (min-width: 768px) {
    text-align: left;
    border-right: ${(props) =>
      props.borderright ? `1px solid #D8D8D8` : null};
    border-bottom: none;
  }
`;

const RowContainer = styled(Row)`
  justify-content: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 35px 0;
`;

export default ({
  selectorChangeHandler,
  selector,
  recommended,
  isLoading,
}) => (
  <>
    <Container>
      <Header
        subtitle="orthotic selector | Question ten"
        title="Add your subjective / medical history"
      />
    </Container>
    <LastSection>
      <Container>
        <RowContainer>
          <Column lg="8" md="6" className="mb-5" borderright="true">
            <OptionTitle>A. Diabetes – select where applicable:</OptionTitle>
            <OptionsContainer>
              <SkyBlueButton
                onClick={() =>
                  selectorChangeHandler('medicalHistoryDiabetes', 'Ulcerations')
                }
                className={
                  selector['medicalHistoryDiabetes'] === 'Ulcerations' &&
                  'active'
                }
              >
                Ulcerations
              </SkyBlueButton>
              <SkyBlueButton
                onClick={() =>
                  selectorChangeHandler('medicalHistoryDiabetes', 'Blisters')
                }
                className={
                  selector['medicalHistoryDiabetes'] === 'Blisters' && 'active'
                }
              >
                Blisters
              </SkyBlueButton>
              <SkyBlueButton
                onClick={() =>
                  selectorChangeHandler('medicalHistoryDiabetes', 'Amputations')
                }
                className={
                  selector['medicalHistoryDiabetes'] === 'Amputations' &&
                  'active'
                }
              >
                Amputations
              </SkyBlueButton>
            </OptionsContainer>
            <OptionTitle>B. Arthritis – select where applicable:</OptionTitle>
            <OptionsContainer>
              <SkyBlueButton
                onClick={() =>
                  selectorChangeHandler('medicalHistoryArthritis', 'Osteo')
                }
                className={
                  selector['medicalHistoryArthritis'] === 'Osteo' && 'active'
                }
              >
                Osteo
              </SkyBlueButton>
              <SkyBlueButton
                onClick={() =>
                  selectorChangeHandler('medicalHistoryArthritis', 'Gout')
                }
                className={
                  selector['medicalHistoryArthritis'] === 'Gout' && 'active'
                }
              >
                Gout
              </SkyBlueButton>
              <SkyBlueButton
                onClick={() =>
                  selectorChangeHandler('medicalHistoryArthritis', 'Rheumatic')
                }
                className={
                  selector['medicalHistoryArthritis'] === 'Rheumatic' &&
                  'active'
                }
              >
                Rheumatic
              </SkyBlueButton>
            </OptionsContainer>
            <OptionTitle>C. Other:</OptionTitle>
            <Row>
              <Col md="6">
                <StyledInput
                  onChange={(e) =>
                    selectorChangeHandler('medicalHistoryOther', e.target.value)
                  }
                  type="text"
                  placeholder="Write other here…"
                  className="mb-2"
                  value={selector['medicalHistoryOther']}
                />
              </Col>
            </Row>
            <PageNavigator
              prevURL="/structuralabnormalities"
              nextURL="/footpain"
            />
          </Column>
          <Column lg="4" md="6" className="mb-5 text-center">
            <Recommendation isLoading={isLoading} recommended={recommended} />
          </Column>
        </RowContainer>
      </Container>
      <Footer margintop="2" progressBar={10}/>
    </LastSection>
  </>
);
