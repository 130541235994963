import React from 'react';
import styled from 'styled-components';
import { SkyBlueButton } from '../config/styles';

const Option = styled.div`
  margin-bottom: 4rem;
  @media (min-width: 992px) {
    margin-bottom: 0;
    display: flex;
  }
`;
const OptionImg = styled.img`
  box-sizing: border-box;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  max-width: 300px;
  margin-bottom: 35px;
  box-shadow: 0 2px 36px 0 rgba(1, 28, 47, 0.1);
`;

const OptionDesc = styled.div`
  @media (min-width: 992px) {
    padding: 5px 30px;
  }
`;

export const OptionTitle = styled.h2`
  font-family: 'Montserrat Bold';
  font-size: 21px;
  line-height: 30px;
  color: ${(props) => props.theme.gray};
`;

const OptionText = styled.p`
  font-size: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 40px;
  color: ${(props) => props.theme.gray};
`;

export default ({
  img,
  title,
  text,
  btn,
  selectorChangeHandler,
  setSelector,
  type,
  selector,
  onChange
}) => {
  const onSelect = () => {
    if (onChange) onChange()
    if (setSelector) {
      setSelector(type, btn)
    } else {
      selectorChangeHandler(type, btn)
    }
  }
  return (
    <Option>
      <OptionImg src={img} />
      <OptionDesc>
        <OptionTitle dangerouslySetInnerHTML={{ __html: title }} />
        <OptionText>{text}</OptionText>
        <SkyBlueButton
          onClick={onSelect}
          className={selector[type] === btn && 'active'}
        >
          {btn}
        </SkyBlueButton>
      </OptionDesc>
    </Option>
  );
};
