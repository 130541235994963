export const PlantarDiagnosis = [
  {
    regionNo: 1,
    diagnosis: ["Tailor’s Bunions", "Neuroma", "Osteoarthritis"]
  },
  {
    regionNo: 2,
    diagnosis: ["Morton’s Neuroma", "Plantar Plate Rupture", "Osteoarthritis"]
  },
  {
    regionNo: 3,
    diagnosis: ["Bunions", "Hullux Valgus", "Sesamoiditis", "Turf Toe", "Neuroma", "Hallux Limitus / Rigidus", "Osteoarthritis"]
  },
  {
    regionNo: 4,
    diagnosis: ["Inter Metatarsal Bursitis", "Lisfrancs Injury", "Stress Fracture", "Osteoarthritis"]
  },
  {
    regionNo: 5,
    diagnosis: ["Plantar Fascittis", "Lisfrancs Fracture", "Stress fracture", "Neuroma / Nerve Entrapment", "Bursitis", "Osteoarthritis"]
  },
  {
    regionNo: 6,
    diagnosis: ["Tarsal Tunnel Syndrome", "Plantar Fascittis", "Accessory Navicular"]
  },
  {
    regionNo: 7,
    diagnosis: ["Plantar Fascittis", "Kohlers Disease", "Plantar Fibroma", "Posterior Tibal Tendon Dysfunction", "Jones Fracture"]
  },
  {
    regionNo: 8,
    diagnosis: ["Plantar Fascittis", "Heel Spur", "Fat pad displacement"]
  },
  {
    regionNo: 9,
    diagnosis: ["Plantar Fascittis", "Heel Spur", "Fat pad displacement"]
  },
]

export const LateralDiagnosis = [
  {
    regionNo: 1,
    diagnosis: ["Peroneal Tendon Injury", "Medial Tibial Stress Syndrome"]
  },
  {
    regionNo: 2,
    diagnosis: ["Sever’s Disease", "Pump Bump", "Os Trigonum Syndrome", "Achillies Tendinopathy"]
  },
  {
    regionNo: 3,
    diagnosis: ["High Ankle Sprain", "Tarsal Coalition", "Neuroma / Nerve Entrapment"]
  },
  {
    regionNo: 4,
    diagnosis: ["Retrocalcaneal Bursitis", "Posterior Heel Spur"]
  },
  {
    regionNo: 5,
    diagnosis: ["Sinus Tarsi Syndrome", "Ankle Sprain"]
  },
  {
    regionNo: 6,
    diagnosis: ["Jones Fracture", "Tarsal Coalition", "Cubiod Syndrome"]
  },
  {
    regionNo: 7,
    diagnosis: ["Freiberg Disease", "Intermetatarsal Bursitis"]
  },
  {
    regionNo: 8,
    diagnosis: ["Capsulitis / Synovitis", "Hammer Toe"]
  },
]

export const DorsalDiagnosis = [
  {
    regionNo: 1,
    diagnosis: ["Extensor Tendinitis"]
  },
  {
    regionNo: 2,
    diagnosis: ["Lateral Ankle Sprain", "Tarsal Tunnel Syndrome"]
  },
  {
    regionNo: 3,
    diagnosis: ["Lisfrancs Fracture", "Tarsal Tunnel Syndrome"]
  },
  {
    regionNo: 4,
    diagnosis: ["Jones Fracture", "Cuboid Syndrome"]
  },
  {
    regionNo: 5,
    diagnosis: ["Inter Metatarsal Bursitis", "Ganglion Cyst"]
  },
  {
    regionNo: 6,
    diagnosis: ["Morton’s Neuroma", "Metatarsalgia", "Stress Fracture"]
  },
  {
    regionNo: 7,
    diagnosis: ["Plantar Plate Tear", "Hammer Toe", "Capsulitis / Synovitis"]
  },
]

export const DiagnosisDescriptionList = [
  {
    name: "Plantar Fascittis",
    description: "Plantar fasciitis is a condition that causes pain on the bottom of the heel. It occurs when the band of tissue that supports the arch of your foot becomes inflamed."
  },
  {
    name: "Posterior Heel Spur",
    description: "A heel spur is a foot condition that's created by a bony-like growth, called a calcium deposit, that extends between your heel bone and arch."
  },
  {
    name: "Heel Spur",
    description: "A heel spur is a foot condition that's created by a bony-like growth, called a calcium deposit, that extends between your heel bone and arch."
  },
  {
    name: "Fat Pad Displacement",
    description: "The Fat Pad can be displaced or thinned, exposing the heel bone and causing a bruised feeling."
  },
  {
    name: "Accessory Navicular Syndrome",
    description: "Some people with this extra bone develop a painful condition known as Accessory Navicular Syndrome when the bone or posterior tibial tendon are aggravated."
  },
  {
    name: "Accessory Navicular",
    description: "Some people with this extra bone develop a painful condition known as Accessory Navicular Syndrome when the bone or posterior tibial tendon are aggravated."
  },
  {
    name: "Tarsal Tunnel Syndrome",
    description: "Tarsal tunnel syndrome is a compression, or squeezing, on the posterior tibial nerve that produces symptoms anywhere along the path of the nerve running from the inside of the ankle into the foot."
  },
  {
    name: "Kohlers Disease",
    description: "Kohhlers is a rare bone disorder of the foot found in children between six and nine years of age. The disease typically affects boys, but it can also affect girls.  It is caused when the navicular bone temporarily loses its blood supply."
  },
  {
    name: "Plantar Fibroma",
    description: "A heel spur is a foot condition that's created by a bony-like growth, called a calcium deposit, that extends between your heel bone and arch."
  },
  {
    name: "Heel Spur",
    description: "A plantar fibroma is a fibrous knot (nodule) in the arch of the foot. It is embedded within the plantar fascia, a band of tissue that extends from the heel to the toes on the bottom of the foot."
  },
  {
    name: "Posterior Tibial Tendon Dysfunction",
    description: "Posterior tibial tendon dysfunction (PTTD) is a condition caused by changes in the tendon, impairing its ability to support the arch. This results in flattening of the foot."
  },
  {
    name: "Jones Fracture",
    description: "A Jones fracture is a break between the base and middle part of the fifth metatarsal of the foot. It results in pain near the midportion of the foot on the outside."
  },
  {
    name: "Inter Metatarsal Bursitis",
    description: "Intermetatarsal bursitis is inflammation of a small fluid filled sac. that lies between the metatarsal heads. The bursa becomes swollen and can cause. pressure on the nerve in the web space."
  },
  {
    name: "Lisfrancs Fracture",
    description: "Lisfranc (midfoot) injuries result if bones in the midfoot are broken or ligaments that support the midfoot are torn."
  },
  {
    name: "Lisfrancs Injury",
    description: "Lisfranc (midfoot) injuries result if bones in the midfoot are broken or ligaments that support the midfoot are torn."
  },
  {
    name: "Stress Fracture",
    description: "A stress fracture is a small crack in a bone, or severe bruising within a bone. Most stress fractures are caused by overuse and repetitive activity."
  },
  {
    name: "Neuroma",
    description: "Neuroma is a painful condition, also referred to as a “pinched nerve” or a nerve tumor. It is a benign growth of nerve tissue frequently found between the third and fourth toes. It brings on pain, a burning sensation, tingling, or numbness between the toes and in the ball of the foot."
  },
  {
    name: "Neuroma / Nerve Entrapment",
    description: "Neuroma is a painful condition, also referred to as a “pinched nerve” or a nerve tumor. It is a benign growth of nerve tissue frequently found between the third and fourth toes. It brings on pain, a burning sensation, tingling, or numbness between the toes and in the ball of the foot."
  },
  {
    name: "Morton’s Neuroma",
    description: "Neuroma is a painful condition, also referred to as a “pinched nerve” or a nerve tumor. It is a benign growth of nerve tissue frequently found between the third and fourth toes. It brings on pain, a burning sensation, tingling, or numbness between the toes and in the ball of the foot."
  },
  {
    name: "Nerve Entrapment",
    description: "Neuroma is a painful condition, also referred to as a “pinched nerve” or a nerve tumor. It is a benign growth of nerve tissue frequently found between the third and fourth toes. It brings on pain, a burning sensation, tingling, or numbness between the toes and in the ball of the foot."
  },
  {
    name: "Turf Toe",
    description: "Turf toe is a sprain of the big toe joint resulting from injury during sports activities. The injury usually results from excessive upward bending of the big toe joint."
  },
  {
    name: "Hallux Limitus / Rigidus",
    description: "Hallux rigidus is the loss of flexibility in the big toe due to arthritis in the MTP joint. In an earlier stage of the condition, called hallux limitus, movement is only somewhat affected."
  },
  {
    name: "Osteoarthritis",
    description: "Osteoarthritis occurs when the protective cartilage that cushions the ends of your bones wears down over time."
  },
  {
    name: "Plantar Plate Tear",
    description: "A plantar plate tear is a common injury of the forefoot which is caused by repetitive overload. Often results in toe deformity."
  },
  {
    name: "Bunions",
    description: "A bunion is a painful bony bump that develops on the inside of the foot at the big toe joint. Bunions are often referred to as hallux valgus."
  },
  {
    name: "Hallux Valgus",
    description: "A bunion is a painful bony bump that develops on the inside of the foot at the big toe joint. Bunions are often referred to as hallux valgus."
  },
  {
    name: "Sesamoiditis",
    description: "Sesamoiditis usually refers to inflammation of the tendons associated with the sesamoids in the foot."
  },
  {
    name: "Tailor’s Bunions",
    description: "A tailor's bunion, also called a bunionette, is a bony lump that forms along the side of the little toe."
  },
  {
    name: "Ankle Sprain",
    description: "A sprained ankle is an injury that occurs when you roll, twist or turn your ankle in an awkward way."
  },
  {
    name: "High Ankle Sprain",
    description: "A sprained ankle is an injury that occurs when you roll, twist or turn your ankle in an awkward way."
  },
  {
    name: "Lateral Ankle Sprain",
    description: "A sprained ankle is an injury that occurs when you roll, twist or turn your ankle in an awkward way."
  },
  {
    name: "Sever’s Disease",
    description: "Sever's disease  is one of the most common causes of heel pain in growing children and adolescents. It is an inflammation of the growth plate in the calcaneus (heel)."
  },
  {
    name: "Medial Tibial Stress Syndrome",
    description: "Medial tibial stress syndrome (MTSS) is an overuse injury or repetitive-stress injury of the shin area."
  },
  {
    name: "Pump Bump / Haglund’s",
    description: "Haglund's deformity is a bony enlargement on the back of the heel. The soft tissue near the Achilles tendon becomes irritated when the bony enlargement rubs against shoes. This often leads to painful bursitis, which is an inflammation of the bursa."
  },
  {
    name: "Pump Bump",
    description: "Haglund's deformity is a bony enlargement on the back of the heel. The soft tissue near the Achilles tendon becomes irritated when the bony enlargement rubs against shoes. This often leads to painful bursitis, which is an inflammation of the bursa."
  },
  {
    name: "Haglund’s",
    description: "Haglund's deformity is a bony enlargement on the back of the heel. The soft tissue near the Achilles tendon becomes irritated when the bony enlargement rubs against shoes. This often leads to painful bursitis, which is an inflammation of the bursa."
  },
  {
    name: "Hammer Toe",
    description: "A hammer toe is a deformity that causes your toe to bend or curl downward instead of pointing forward."
  },
  {
    name: "Gaglion Cyst",
    description: "A ganglion cyst is a small sac of fluid that forms over a joint or tendon."
  },
  {
    name: "Metatarsalgia",
    description: "Metatarsalgia is a condition in which the ball of your foot becomes painful and inflamed."
  },
  {
    name: "Capsulitis",
    description: "Capsulitis occurs when Ligaments surrounding the joint become inflamed and irritated."
  },
  {
    name: "Capsulitis / Synovitis",
    description: "Capsulitis occurs when Ligaments surrounding the joint become inflamed and irritated."
  },
  {
    name: "Intermetatarsal Bursitis",
    description: "Intermetatarsal bursitis is inflammation of a small fluid filled sac that lies between the metatarsal heads. The bursa becomes swollen and can cause. pressure on the nerve in the web space."
  },
  {
    name: "Freiberg Disease",
    description: "Freiberg disease, also known as a Freiberg infraction, is a form of avascular necrosis in the metatarsal bone of the foot. It generally develops in the second metatarsal, but can occur in any metatarsal."
  },
  {
    name: "Retrocalcaneal Bursitis",
    description: "Retrocalcaneal bursitis is a condition that causes heel pain. This pain spreads from the bursa located between the Achilles tendon and the heel bone. This bursa normally provides a cushion as you walk."
  },
  {
    name: "Tarsal Coalition",
    description: "A tarsal coalition is an abnormal connection that develops between two bones in the back of the foot (the tarsal bones). This abnormal connection, which can be composed of bone, cartilage or fibrous tissue, may lead to limited motion and pain in one or both feet."
  },
  {
    name: "Achillies Tendinopathy",
    description: "Achilles tendinopathy is a common overuse injury caused by repetitive energy storage and release with excessive compression. This can lead to a sudden injury, or in the worst case, can cause a rupture of the Achilles tendon."
  },
  {
    name: "Os Trigonum Syndrome",
    description: "The Os Trigonum Syndrome refers to pain posterior of the ankle and reduced plantarflexion caused by “the nutcracker-phenomenon”. When an os trigonum is present, this accessory ossicle together with surrounding soft tissues can become wedged between the tibia, talus and calcaneus."
  }
]
