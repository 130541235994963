import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import Footer, { LastSection } from '../../components/Footer';
import Header from '../../components/Header';
import Option from '../../components/Option';
import PageNavigator from '../../components/PageNavigator';
import Recommendation from '../../components/Recommendation';
import TutorialButton from '../../components/TutorialButton';

import { Checkbox } from '../../config/styles';

const Column = styled(Col)`
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: ${(props) => (props.borderright ? `1px solid #D8D8D8` : null)};
  border-right: none;
  @media (min-width: 768px) {
    text-align: left;
    border-right: ${(props) =>
      props.borderright ? `1px solid #D8D8D8` : null};
    border-bottom: none;
  }
`;

const RowContainer = styled(Row)`
  justify-content: center;
`;

const CheckboxContainer = styled.div`
  margin-bottom: 1.4rem;
`;

export default ({
  isLoading,
  recommended,
  selector,
  selectorChangeHandler,
}) => {
  const onSelectToeDirection = (type, input) => {
    selectorChangeHandler(type, input);
  };

  return (
    <>
      <Container>
        <Header
          subtitle="orthotic selector | question two"
          title="What is your toe direction?"
        />
      </Container>
      <LastSection>
        <Container>
          <TutorialButton text="– Need help with this step? Watch our handy guide for tips & advice." />
          <RowContainer>
            <Column lg="8" md="6" className="mb-5" borderright="true">
              <CheckboxContainer>
                <Checkbox
                  id="toe"
                  label="Tick checkbox if this is for a child’s foot"
                  labelhelper="(for in-toe / out-toe correction)"
                  bold
                  onChange={e => selectorChangeHandler('childsFoot', e.target.checked)}
                  checked={selector.childsFoot}
                />
              </CheckboxContainer>
              <Option
                img="/images/toedirection/toedirection1.png"
                title="Option One.<br/>Out-Toe"
                text="Toes appear outwards"
                btn="out-toe"
                setSelector={onSelectToeDirection}
                selector={selector}
                type="toeDirection"
              />
              <Option
                img="/images/toedirection/toedirection2.png"
                title="Option Two.<br/>Neutral "
                text="Toes each side"
                btn="neutral"
                setSelector={onSelectToeDirection}
                selector={selector}
                type="toeDirection"
              />
              <Option
                img="/images/toedirection/toedirection3.png"
                title="Option Three.<br/>In-Toe"
                text="Toes appear inwards"
                btn="in-toe"
                setSelector={onSelectToeDirection}
                selector={selector}
                type="toeDirection"
              />
              <PageNavigator
                prevURL="/foottype"
                nextURL={selector.toeDirection ? '/rearview' : ''}
              />
            </Column>
            <Column lg="4" md="6" className="mb-5 text-center">
              <Recommendation isLoading={isLoading} recommended={recommended} />
            </Column>
          </RowContainer>
        </Container>
        <Footer margintop="2" progressBar={3} />
      </LastSection>
    </>
  );
};
