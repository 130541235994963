import { find } from 'lodash';
import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';

import Footer, { LastSection } from '../../components/Footer';
import FootPainPoints from '../../components/FootPainPoints';
import Header from '../../components/Header';
// import LoginModal from '../../components/LoginModal';
import PageNavigator from '../../components/PageNavigator';

import { CUSTOM_FIT_PRICE, PERSONALISED_FIT_PRICE, theme } from '../../config/constants';
import {
  DarkBlueButton,
  SkyBlueButton,
  StyledInput,
  StyledSelector,
} from '../../config/styles';
import {
  footTypeList,
  toeDirectionList,
  rearViewList,
  navicularBulgingList,
  lateralCurvatureList,
  talarheadPalpationList,
  kneePositionList
} from '../../config/data';
import PlantarView, { OptionTitle } from '../../components/PlantarView';
import LateralView from '../../components/LateralView';
import DorsalView from '../../components/DorsalView';
import CustomInputRange from '../../components/CustomInputRange';
import ExampleModal from '../../components/ExampleModal';

export default ({
  selector,
  user,
  selectorChangeHandler,
  updateUser,
  recommended,
  baseDevice,
  // addToCart,
}) => {
  const [isSaving, setSaving] = useState(false);
  // const [buttonTexts, setButtonText] = useState({});
  const [isEditing, setEditing] = useState({});
  const [exampleImageUrl, setExampleImageUrl] = useState(null);
  const {
    footType,
    toeDirection,
    rearView,
    navicularBulging,
    lateralCurvature,
    talarheadPalpation,
    kneePosition,
    isCustomFit,
    topCover,
  } = selector;
  const selectedTopCover = options.find(({ value }) => value === topCover) || {};

  const onToggleExampleModal = () => {
    if (exampleImageUrl !== null) {
      setExampleImageUrl(null);
    }
  };

  const onClickExample = url => {
    setExampleImageUrl(url);
  };
  // const addedText = product => {
  //   buttonTexts[product.id] = 'Added!';

  //   const prescriptionPayload = getPrescriptionPayload();

  //   setButtonText(buttonTexts);

  //   if (isCustomFit) {
  //     product.price = CUSTOM_FIT_PRICE;
  //     product.stripe_price_id = product.stripe_custom_fit_price_id;
  //   } else {
  //     product.price = PERSONALISED_FIT_PRICE;
  //     product.stripe_price_id = product.stripe_personalised_price_id;
  //   }

  //   addToCart(product, isCustomFit, prescriptionPayload);

  //   setTimeout(() => {
  //     setButtonText({});
  //   }, 2000);
  // }

  const getResultValue = (array, value) => {
    const data = find(array, { value })
    return data ? data.id : null
  }

  const findIndex = {
    footType: getResultValue(footTypeList, footType),
    toeDirection: getResultValue(toeDirectionList, toeDirection),
    rearView: getResultValue(rearViewList, rearView),
    navicularBulging: getResultValue(navicularBulgingList, navicularBulging),
    lateralCurvature: getResultValue(lateralCurvatureList, lateralCurvature),
    talarheadPalpation: getResultValue(talarheadPalpationList, talarheadPalpation),
    kneePosition: getResultValue(kneePositionList, kneePosition),
  }

  const getRearFootAngle = () => {
    return (Math.trunc(selector.rearFootAngle * 100) / 100);
  }

  const getPrescriptionPayload = () => {
    const medicalHistory = [];
    if (selector.medicalHistoryDiabetes) {
      medicalHistory.push('Diabetes');
    }
    if (selector.medicalHistoryArthritis) {
      medicalHistory.push('Arthritis');
    }
    if (selector.medicalHistoryOther) {
      medicalHistory.push(selector.medicalHistoryOther);
    }

    let userId = '';
    if (user) {
      userId = JSON.parse(user).id;
    }

    return {
      left_foot_watermark: selector.leftFootWatermark,
      right_foot_watermark: selector.rightFootWatermark,
      foot_pain_plantar: selector.footPainPlantar,
      foot_pain_dorsal: selector.footPainDorsal,
      foot_pain_lateral: selector.footPainLateral,
      foot_type: selector.footType,
      child_foot: selector.childsFoot,
      toe_direction: selector.toeDirection,
      rear_foot_view: selector.rearView,
      navicular_bulging: selector.navicularBulging,
      malleloar_curvature: selector.lateralCurvature,
      talar_head_palpatation: selector.talarheadPalpation,
      knee_position: selector.kneePosition,
      structural_abnormalities: selector.structuralAbnormalities,
      medical_history: medicalHistory,
      body_weight: selector.bodyWeight,
      shoe_size: selector.shoeSize,
      shoe_type: selector.shoeType,
      left_foot_longest_point: selector.leftFootLP,
      left_foot_widest_point: selector.leftFootWP,
      right_foot_longest_point: selector.rightFootLP,
      right_foot_widest_point: selector.rightFootWP,
      arch_height: selector.archHeight,
      shell_length: selector.shellLength,
      shell_width: selector.shellWidth,
      shell_thickness: selector.shellThickness,
      rear_foot_correction_angle: Number(getRearFootAngle()),
      device_width: selector.deviceWidth,
      eva_density: selector.evaDensity,
      top_cover: selector.topCover,
      custom_fit: selector.isCustomFit,
      base_device: baseDevice,
      user: userId,
      productID: recommended.id,
    };
  }

  const onSavePrescription = (e) => {
    e.preventDefault();
    setSaving(true);

    const payload = getPrescriptionPayload();

    let redirectUrl = `https://www.podstep.com.au/register/?json=${JSON.stringify(payload)}`;

    if (recommended && recommended.title) {
      redirectUrl += `&productName=${recommended.title}`
    }

    if (recommended && recommended.url) {
      redirectUrl += `&url=${recommended.url}`;
    }

    window.location.replace(redirectUrl);
  };

  const onChangeSlider = (type, array, id) => {
    const updatedValue = find(array, { id }).value;
    selectorChangeHandler(type, updatedValue);
  }

  return (
    <>
      <Container>
        <Header
          subtitle="view device results"
          title="Edit your device or add to cart"
        />
      </Container>

      <Container>
        {
          recommended &&
          (
            <>
              <ProductImageContainer>
                <ProductImage src={recommended.img} alt="" />
              </ProductImageContainer>
              <ProductInfo>
                <ProductPrice>
                  Price: <span>${(isCustomFit ? CUSTOM_FIT_PRICE : PERSONALISED_FIT_PRICE).toFixed(2)}</span>
                </ProductPrice>
                <div>{recommended.title}</div>
                {/* <InvertedRedButton onClick={() => addedText(recommended)}>
                  {buttonTexts[recommended.id] || 'Add to Cart'}
                </InvertedRedButton> */}
              </ProductInfo>
            </>
          )
        }
        <AdjustSection>
          <BoldText className="mb-2">Adjust:</BoldText>
          <AdjustRow>
            <AdjustCol1>
              Arch Height
              <br />
              <ExampleLink
                type="button"
                onClick={() => onClickExample('https://storage.googleapis.com/podstep-api.appspot.com/GIFS/Arch-height.gif')}
                title="Arch Height Example"
              >
                Example
              </ExampleLink>
            </AdjustCol1>
            <AdjustCol2>{selector.archHeight}mm</AdjustCol2>
            <AdjustCol3 className="hidden-range-min-max">
              <CustomInputRange
                maxValue={30}
                minValue={15}
                step={5}
                value={selector.archHeight || 15}
                onChange={(val) => selectorChangeHandler('archHeight', val)}
                disabled={!isEditing.archHeight}
              />
            </AdjustCol3>
            <AdjustCol4
              onClick={() => setEditing({ ...isEditing, archHeight: !isEditing.archHeight })}
              isEditing={isEditing.archHeight}
            >
              {isEditing.archHeight ? 'SAVE EDIT' : 'EDIT'}
            </AdjustCol4>
          </AdjustRow>
          <AdjustRow>
            <AdjustCol1>
              Shell Thickness
              <br />
              <ExampleLink
                type="button"
                onClick={() => onClickExample('https://storage.googleapis.com/podstep-api.appspot.com/GIFS/Shell-thickness.gif')}
                title="Shell Thickness Example"
              >
                Example
              </ExampleLink>
            </AdjustCol1>
            <AdjustCol2>{selector.shellThickness}mm</AdjustCol2>
            <AdjustCol3 className='hidden-range-min-max'>
              <CustomInputRange
                maxValue={6}
                minValue={3}
                step={0.5}
                value={selector.shellThickness || 3}
                onChange={(val) => selectorChangeHandler('shellThickness', val)}
                disabled={!isEditing.shellThickness}
              />
            </AdjustCol3>
            <AdjustCol4
              onClick={() => setEditing({ ...isEditing, shellThickness: !isEditing.shellThickness })}
              isEditing={isEditing.shellThickness}
            >
              {isEditing.shellThickness ? 'SAVE EDIT' : 'EDIT'}
            </AdjustCol4>
          </AdjustRow>
          <AdjustRow>
            <AdjustCol1>
              Shell Length
              <br />
              <ExampleLink
                type="button"
                onClick={() => onClickExample('https://storage.googleapis.com/podstep-api.appspot.com/GIFS/Shell-length.gif')}
                title="Shell Length Example"
              >
                Example
              </ExampleLink>
            </AdjustCol1>
            <AdjustCol2>{selector.shellLength.toFixed(1)}mm</AdjustCol2>
            <AdjustCol4 />
            <AdjustCol3 className='hidden-range-min-max'>
              <CustomInputRange
                maxValue={205}
                minValue={20}
                step={1}
                value={selector.shellLength || 20}
                onChange={(val) => selectorChangeHandler('shellLength', val)}
                disabled={!isEditing.shellLength}
              />
            </AdjustCol3>
            <AdjustCol4
              onClick={() => setEditing({ ...isEditing, shellLength: !isEditing.shellLength })}
              isEditing={isEditing.shellLength}
            >
              {isEditing.shellLength ? 'SAVE EDIT' : 'EDIT'}
            </AdjustCol4>
          </AdjustRow>
          <AdjustRow>
            <AdjustCol1>
              Shell Width
              <br />
              <ExampleLink
                type="button"
                onClick={() => onClickExample('https://storage.googleapis.com/podstep-api.appspot.com/GIFS/Shell-width.gif')}
                title="Shell Width Example"
              >
                Example
              </ExampleLink>
            </AdjustCol1>
            <AdjustCol2>{selector.shellWidth.toFixed(1)}mm</AdjustCol2>
            <AdjustCol4 />
            <AdjustCol3 className='hidden-range-min-max'>
              <CustomInputRange
                maxValue={93}
                minValue={5}
                step={1}
                value={selector.shellWidth || 5}
                onChange={(val) => selectorChangeHandler('shellWidth', val)}
                disabled={!isEditing.shellWidth}
              />
            </AdjustCol3>
            <AdjustCol4
              onClick={() => setEditing({ ...isEditing, shellWidth: !isEditing.shellWidth })}
              isEditing={isEditing.shellWidth}
            >
              {isEditing.shellWidth ? 'SAVE EDIT' : 'EDIT'}
            </AdjustCol4>
          </AdjustRow>
          <AdjustRow>
            <AdjustCol1>Rear foot correction angle</AdjustCol1>
            <AdjustCol2>{getRearFootAngle()}˚</AdjustCol2>
            <AdjustCol4 />
            <AdjustCol3 className='hidden-range-min-max'>
              {/* <CustomInputRange
                maxValue={7}
                minValue={0}
                step={0.5}
                value={localRearFootAngle}
                onChange={(val) => setRearFootAngle(val)}
                disabled={!isEditing.rearFootAngle}
              /> */}
            </AdjustCol3>
            {/* <AdjustCol4
              onClick={() => onSaveOrEditRearFoot()}
              isEditing={isEditing.rearFootAngle}
            >
              { isEditing.rearFootAngle ? 'SAVE EDIT' : 'EDIT' }
            </AdjustCol4> */}
          </AdjustRow>
          <AdjustRow>
            <AdjustCol3>
              <StyledSelector
                fullWidth
                options={options}
                placeholder='Select Top Cover (if required)'
                onChange={option => selectorChangeHandler('topCover', option.value)}
                value={selectedTopCover.value}
              />
            </AdjustCol3>
            <AdjustCol1 />
            <AdjustCol2 />
          </AdjustRow>
          <SaveContainer>
            {/* {!user && (
              <LoginModal
                btnText='Login or Create an Account'
                linkedStyle={true}
                updateUser={updateUser}
              />
            )} */}
            <CustomDarkBlueButton
              disabled={isSaving}
              onClick={onSavePrescription}
            >
              {isSaving ? 'Saving...' : 'Save my Prescription'}
            </CustomDarkBlueButton>
          </SaveContainer>
        </AdjustSection>
      </Container>
      <LastSection>
        <ResultsContainer>
          <ResultsRow>
            <ResultsCol1>
              <ResultsHeader>Question</ResultsHeader>
            </ResultsCol1>
            <ResultsCol2></ResultsCol2>
            <ResultsCol3>
              <ResultsHeader>Edit Results</ResultsHeader>
            </ResultsCol3>
          </ResultsRow>
          <ResultsRow>
            <ResultsCol1>
              <RegText>1. Your Foot Type</RegText>{' '}
              <BoldText>{selector.footType}</BoldText>
            </ResultsCol1>
            <ResultsCol2>
              <img src={`/images/foottype/foottype${findIndex.footType}.png`} alt='' />
            </ResultsCol2>
            <ResultsCol3 className='hidden-range-min-max hidden-value'>
              <CustomInputRange
                minValue={1}
                maxValue={3}
                onChange={value => onChangeSlider('footType', footTypeList, value)}
                value={findIndex.footType || 1}
                disabled={!isEditing.footType}
              />
            </ResultsCol3>
            <ResultsCol4
              onClick={() => setEditing({ ...isEditing, footType: !isEditing.footType })}
              isEditing={isEditing.footType}
            >
              {isEditing.footType ? 'SAVE EDIT' : 'EDIT'}
            </ResultsCol4>
          </ResultsRow>
          <ResultsRow>
            <ResultsCol1>
              <RegText>2. Toe Direction</RegText>{' '}
              <BoldText>{selector.toeDirection}</BoldText>
            </ResultsCol1>
            <ResultsCol2>
              <img
                src={`/images/toedirection/toedirection${findIndex.toeDirection}.png`}
                alt=''
              />
            </ResultsCol2>
            <ResultsCol3 className="hidden-range-min-max hidden-value">
              <CustomInputRange
                minValue={1}
                maxValue={3}
                onChange={(e) => onChangeSlider('toeDirection', toeDirectionList, e)}
                value={findIndex.toeDirection || 1}
                disabled={!isEditing.toeDirection}
              />
            </ResultsCol3>
            <ResultsCol4
              onClick={() => setEditing({ ...isEditing, toeDirection: !isEditing.toeDirection })}
              isEditing={isEditing.toeDirection}
            >
              {isEditing.toeDirection ? 'SAVE EDIT' : 'EDIT'}
            </ResultsCol4>
          </ResultsRow>
          <ResultsRow>
            <ResultsCol1>
              <RegText>3. Rear Foot View</RegText>{' '}
              <BoldText>{selector.rearView}</BoldText>
            </ResultsCol1>
            <ResultsCol2>
              <img src={`/images/rearview/rearview${findIndex.rearView}.png`} alt='' />
            </ResultsCol2>
            <ResultsCol3 className='hidden-range-min-max hidden-value'>
              <CustomInputRange
                minValue={1}
                maxValue={3}
                onChange={(e) => onChangeSlider('rearView', rearViewList, e)}
                value={findIndex.rearView || 1}
                disabled={!isEditing.rearView}
              />
            </ResultsCol3>
            <ResultsCol4
              onClick={() => setEditing({ ...isEditing, rearView: !isEditing.rearView })}
              isEditing={isEditing.rearView}
            >
              {isEditing.rearView ? 'SAVE EDIT' : 'EDIT'}
            </ResultsCol4>
          </ResultsRow>
          <ResultsRow>
            <ResultsCol1>
              <RegText>4. Navicular Bulging</RegText>{' '}
              <BoldText>{selector.navicularBulging}</BoldText>
            </ResultsCol1>
            <ResultsCol2>
              <img
                src={`/images/navicularbulging/navicularbulging${findIndex.navicularBulging}.png`}
                alt=''
              />
            </ResultsCol2>
            <ResultsCol3 className="hidden-range-min-max hidden-value">
              <CustomInputRange
                minValue={1}
                maxValue={3}
                onChange={(e) => onChangeSlider('navicularBulging', navicularBulgingList, e)}
                value={findIndex.navicularBulging || 1}
                disabled={!isEditing.navicularBulging}
              />
            </ResultsCol3>
            <ResultsCol4
              onClick={() => setEditing({ ...isEditing, navicularBulging: !isEditing.navicularBulging })}
              isEditing={isEditing.navicularBulging}
            >
              {isEditing.navicularBulging ? 'SAVE EDIT' : 'EDIT'}
            </ResultsCol4>
          </ResultsRow>
          <ResultsRow>
            <ResultsCol1>
              <RegText>5. Lateral Ankle</RegText>{' '}
              <BoldText>{selector.lateralCurvature}</BoldText>
            </ResultsCol1>
            <ResultsCol2>
              <img
                src={`/images/lateralcurvature/lateralcurvature${findIndex.lateralCurvature}.png`}
                alt=''
              />
            </ResultsCol2>
            <ResultsCol3 className="hidden-range-min-max hidden-value">
              <CustomInputRange
                minValue={1}
                maxValue={3}
                onChange={(e) => onChangeSlider('lateralCurvature', lateralCurvatureList, e)}
                value={findIndex.lateralCurvature || 1}
                disabled={!isEditing.lateralCurvature}
              />
            </ResultsCol3>
            <ResultsCol4
              onClick={() => setEditing({ ...isEditing, lateralCurvature: !isEditing.lateralCurvature })}
              isEditing={isEditing.lateralCurvature}
            >
              {isEditing.lateralCurvature ? 'SAVE EDIT' : 'EDIT'}
            </ResultsCol4>
          </ResultsRow>
          <ResultsRow>
            <ResultsCol1>
              <RegText>6. Talar Head Palpation</RegText>{' '}
              <BoldText>{selector.talarheadPalpation}</BoldText>
            </ResultsCol1>
            <ResultsCol2>
              <img
                src={`/images/talarheadpalpation/talarheadpalpation${findIndex.talarheadPalpation}.png`}
                alt=''
              />
            </ResultsCol2>
            <ResultsCol3 className="hidden-range-min-max hidden-value">
              <CustomInputRange
                minValue={1}
                maxValue={3}
                onChange={(e) => onChangeSlider('talarheadPalpation', talarheadPalpationList, e)}
                value={findIndex.talarheadPalpation || 1}
                disabled={!isEditing.talarheadPalpation}
              />
            </ResultsCol3>
            <ResultsCol4
              onClick={() => setEditing({ ...isEditing, talarheadPalpation: !isEditing.talarheadPalpation })}
              isEditing={isEditing.talarheadPalpation}
            >
              {isEditing.talarheadPalpation ? 'SAVE EDIT' : 'EDIT'}
            </ResultsCol4>
          </ResultsRow>
          <ResultsRow>
            <ResultsCol1>
              <RegText>7. Knee Position</RegText>{' '}
              <BoldText>{selector.kneePosition}</BoldText>
            </ResultsCol1>
            <ResultsCol2>
              <img
                src={`/images/kneeposition/kneeposition${findIndex.kneePosition}.png`}
                alt=''
              />
            </ResultsCol2>
            <ResultsCol3 className="hidden-range-min-max hidden-value">
              <CustomInputRange
                minValue={1}
                maxValue={3}
                onChange={(e) => onChangeSlider('kneePosition', kneePositionList, e)}
                value={findIndex.kneePosition || 1}
                disabled={!isEditing.kneePosition}
              />
            </ResultsCol3>
            <ResultsCol4
              onClick={() => setEditing({ ...isEditing, kneePosition: !isEditing.kneePosition })}
              isEditing={isEditing.kneePosition}
            >
              {isEditing.kneePosition ? 'SAVE EDIT' : 'EDIT'}
            </ResultsCol4>
          </ResultsRow>
          <ResultsRow>
            <ResultsCol1>
              <RegText>Medical history</RegText>{' '}
            </ResultsCol1>
            <ResultsCol3>
              {
                isEditing.medicalHistory
                  ? (
                    <RowContainer>
                      <Column lg="12" md="12" className="mb-5">
                        <OptionTitle>A. Diabetes – select where applicable:</OptionTitle>
                        <OptionsContainer>
                          <SkyBlueButton
                            onClick={() =>
                              selectorChangeHandler('medicalHistoryDiabetes', 'Ulcerations')
                            }
                            className={
                              selector['medicalHistoryDiabetes'] === 'Ulcerations' &&
                              'active'
                            }
                          >
                            Ulcerations
                          </SkyBlueButton>
                          <SkyBlueButton
                            onClick={() =>
                              selectorChangeHandler('medicalHistoryDiabetes', 'Blisters')
                            }
                            className={
                              selector['medicalHistoryDiabetes'] === 'Blisters' && 'active'
                            }
                          >
                            Blisters
                          </SkyBlueButton>
                          <SkyBlueButton
                            onClick={() =>
                              selectorChangeHandler('medicalHistoryDiabetes', 'Amputations')
                            }
                            className={
                              selector['medicalHistoryDiabetes'] === 'Amputations' &&
                              'active'
                            }
                          >
                            Amputations
                          </SkyBlueButton>
                        </OptionsContainer>
                        <OptionTitle>B. Arthritis – select where applicable:</OptionTitle>
                        <OptionsContainer>
                          <SkyBlueButton
                            onClick={() =>
                              selectorChangeHandler('medicalHistoryArthritis', 'Osteo')
                            }
                            className={
                              selector['medicalHistoryArthritis'] === 'Osteo' && 'active'
                            }
                          >
                            Osteo
                          </SkyBlueButton>
                          <SkyBlueButton
                            onClick={() =>
                              selectorChangeHandler('medicalHistoryArthritis', 'Gout')
                            }
                            className={
                              selector['medicalHistoryArthritis'] === 'Gout' && 'active'
                            }
                          >
                            Gout
                          </SkyBlueButton>
                          <SkyBlueButton
                            onClick={() =>
                              selectorChangeHandler('medicalHistoryArthritis', 'Rheumatic')
                            }
                            className={
                              selector['medicalHistoryArthritis'] === 'Rheumatic' &&
                              'active'
                            }
                          >
                            Rheumatic
                          </SkyBlueButton>
                        </OptionsContainer>
                        <OptionTitle>C. Other:</OptionTitle>
                        <Row>
                          <Col md='6'>
                            <StyledInput
                              onChange={(e) =>
                                selectorChangeHandler('medicalHistoryOther', e.target.value)
                              }
                              type='text'
                              placeholder='Write other here…'
                              className='mb-2'
                              value={selector['medicalHistoryOther']}
                            />
                          </Col>
                        </Row>
                      </Column>
                    </RowContainer>
                  ) : (
                    <div className="text-right">
                      {
                        selector.medicalHistoryDiabetes &&
                        `${selector.medicalHistoryDiabetes}, `
                      }
                      {
                        selector.medicalHistoryArthritis &&
                        `${selector.medicalHistoryArthritis}. `
                      }
                      {
                        selector.medicalHistoryOther &&
                        `Other: ${selector.medicalHistoryOther}`
                      }
                    </div>
                  )
              }
            </ResultsCol3>
            <ResultsCol4
              onClick={() => setEditing({ ...isEditing, medicalHistory: !isEditing.medicalHistory })}
              isEditing={isEditing.medicalHistory}
            >
              {isEditing.medicalHistory ? 'SAVE EDIT' : 'EDIT'}
            </ResultsCol4>
          </ResultsRow>
          <Row className="mb-5 pb-2">
            <FootPainCol md="7" isediting={isEditing.footPainPoints}>
              <HeaderTitle className="mb-4 pb-2">
                Structural Abnormalities
              </HeaderTitle>
              <RegText className="mb-4">
                Photo(s) of foot:
                {
                  selector.leftFootWatermark
                    ? (
                      <a
                        href={selector.leftFootWatermark}
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Left foot photo"
                      >
                        {' Left Foot'}
                      </a>
                    ) : null
                }
                {
                  selector.rightFootWatermark
                    ? (
                      <a
                        href={selector.rightFootWatermark}
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Right foot photo"
                      >
                        {' Right Foot'}
                      </a>
                    ) : null
                }
              </RegText>
              <FootPainPoints
                selectorChangeHandler={selectorChangeHandler}
                selector={selector}
                allowEdit={isEditing.footPainPoints}
              />
            </FootPainCol>
            <EditCol>
              <TagEdit
                onClick={() => setEditing({ ...isEditing, footPainPoints: !isEditing.footPainPoints })}
                isEditing={isEditing.footPainPoints}
              >
                {isEditing.footPainPoints ? 'SAVE EDIT' : 'EDIT'}
              </TagEdit>
            </EditCol>
          </Row>
          <Row className="pb-2">
            <FootPainCol md="7" isediting={isEditing.footPain}>
              <HeaderTitle className="pb-2">
                Foot Pain
              </HeaderTitle>
            </FootPainCol>
          </Row>
          <Row className="mb-5 pb-2">
            <Column lg="10" md="10" className="mb-5" isediting={isEditing.footPain}>
              <PlantarView
                title={`A. Plantar ${isEditing.footPain ? '– Click where it hurts:' : ''}`}
                question={isEditing.footPain ? 'Then select from the list:' : ''}
                selectorChangeHandler={selectorChangeHandler}
                selector={selector}
                type="footPainPlantar"
                viewOnly={!isEditing.footPain}
              />
              <LateralView
                title={`B. Lateral View ${isEditing.footPain ? '– Click where it hurts:' : ''}`}
                question={isEditing.footPain ? 'Then select from the list:' : ''}
                selectorChangeHandler={selectorChangeHandler}
                selector={selector}
                type="footPainLateral"
                viewOnly={!isEditing.footPain}
              />
              <DorsalView
                title={`C. Dorsal View ${isEditing.footPain ? '– Click where it hurts:' : ''}`}
                question={isEditing.footPain ? 'Then select from the list:' : ''}
                selectorChangeHandler={selectorChangeHandler}
                selector={selector}
                type="footPainDorsal"
                viewOnly={!isEditing.footPain}
              />
            </Column>
            <EditCol>
              <TagEdit
                onClick={() => setEditing({ ...isEditing, footPain: !isEditing.footPain })}
                isEditing={isEditing.footPain}
              >
                {isEditing.footPain ? 'SAVE EDIT' : 'EDIT'}
              </TagEdit>
            </EditCol>
          </Row>
          <PageNavigator
            prevURL="/personaldetails"
            nextHidden
            nextURL=""
            footAngle={getRearFootAngle()}
            lastPage
          />
        </ResultsContainer>
        <Footer margintop="8" progressBar={13} />
        <ExampleModal
          isOpen={exampleImageUrl !== null}
          onToggle={onToggleExampleModal}
          imageUrl={exampleImageUrl}
        />
      </LastSection>
    </>
  );
}

const options = [
  { value: 'Poron (Shock Absorption)', label: 'Poron (Shock Absorption)' },
  { value: 'EVA (Deflection properties)', label: 'EVA (Deflection properties)' },
  { value: 'Spenco (Shock Absorption and reduces friction)', label: 'Spenco (Shock Absorption and reduces friction)' },
  { value: 'Vinyl', label: 'Vinyl' },
  { value: 'Cambrelle', label: 'Cambrelle' },
];

const ProductImageContainer = styled.div`
  margin: auto;
  text-align: center;
  margin-bottom: 3rem;
  background: white;
  max-width: 735px;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 2px 36px rgba(1, 28, 47, 0.1);
`;

const ProductImage = styled.img`
  object-fit: contain;
  width: 100%;
  max-height: 400px;
  padding: 0;
  background: white;
  border-radius: 6px;
`;

const ProductInfo = styled.div`
  color: ${theme.gray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 735px;
  margin: auto;
  margin-bottom: 4rem;
`;

const ProductPrice = styled.div`
  span {
    font-size: 21px;
    color: ${theme.black};
  }
`;

// const InvertedRedButton = styled(RedButton)`
//   background: ${theme.red};
//   color: white;
// `;

const BoldText = styled.h1`
  font-family: 'Montserrat Bold';
  font-size: 21px;
  color: ${theme.gray};
  margin-bottom: 0;
  text-transform: capitalize;
`;

const RegText = styled.h1`
  font-family: 'Montserrat Regular';
  font-size: 21px;
  color: ${theme.gray};
  margin-bottom: 0;
`;

const AdjustSection = styled.div`
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

const AdjustRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  position: relative;
`;

const AdjustCol1 = styled.div`
  flex: 1;
`;
const AdjustCol2 = styled.div`
  font-family: 'Montserrat Bold';
  font-size: 21px;
  color: ${theme.gray};
  padding-left: 4rem;
  flex: 2;
`;
const AdjustCol3 = styled.div`
  flex: 2;
  margin-right: 6rem;
  z-index: 2;
  position: relative;
`;
const AdjustCol4 = styled.div`
  z-index: 1;
  position: absolute;
  font-weight: bold;
  right: -2.4rem;
  top: 5px;
  text-align: center;
  min-width: 140px;
  color: ${(props) => props.isEditing ? theme.red : theme.black};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.6;
  }
`;

const SaveContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  button {
    opacity: 1;
    &:hover {
      opacity: 0.7
    }
  }
`;

const CustomDarkBlueButton = styled(DarkBlueButton)`
  width: 350px;
  margin-left: 1rem;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const ResultsContainer = styled(Container)`
  border-top: 1px solid #d8d8d8;
  padding-top: 2rem;
`;

const ResultsHeader = styled.h1`
  font-size: 25px;
  color: #979797;
`;
const ResultsRow = styled.div`
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  position: relative;
  &:first-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 1.5rem;
  }
`;

const ResultsCol1 = styled.div`
  flex: 1;
`;
const ResultsCol2 = styled.div`
  text-align: center;
  flex: 2;
  img {
    height: 95px;
  }
`;
const ResultsCol3 = styled.div`
  flex: 2;
  margin-right: 6rem;
  z-index: 2;
  position: relative;
`;
const ResultsCol4 = styled.div`
  z-index: 1;
  position: absolute;
  font-weight: bold;
  right: -2.4rem;
  text-align: center;
  min-width: 140px;
  color: ${(props) => props.isEditing ? theme.red : theme.black};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.6;
  }
`;

const FootPainCol = styled(Col)`
  transition: all 0.2s ease;
  margin-top: 1.5rem;
  opacity: ${props => props.isediting ? 1 : 0.7};
`;

const EditCol = styled(Col)`
  position: relative;
  margin-top: 1.5rem;
`;

const TagEdit = styled.div`
  font-weight: bold;
  text-align: center;
  position: absolute;
  right: -1.6rem;
  min-width: 140px;
  height: fit-content;
  color: ${(props) => props.isEditing ? theme.red : theme.black};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.6;
  }
`;

const ExampleLink = styled.button`
  background-color: transparent;
  border: 0;
  font-family: 'Europa';
  font-weight: 200;
  color: ${(props) => props.theme.red};
  font-size: 16px;
  letter-spacing: 0.22px;
  line-height: 24px;
  border-bottom: 1px solid;
  &:hover {
    color: ${(props) => props.theme.gray};
  }
`;

const HeaderTitle = styled.h2`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: #4C5B69;
  @media (min-width: 501px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Column = styled(Col)`
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: ${(props) => (props.borderright ? `1px solid #D8D8D8` : null)};
  opacity: ${props => props.isediting ? 1 : 0.7};
  border-right: none;
  @media (min-width: 768px) {
    text-align: left;
    border-right: ${(props) =>
    props.borderright ? `1px solid #D8D8D8` : null};
    border-bottom: none;
  }
`;

const RowContainer = styled(Row)`
  justify-content: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 35px 0;
`;
