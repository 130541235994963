import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import Footer, { LastSection } from '../../components/Footer';
import Header from '../../components/Header';
import Option from '../../components/Option';
import PageNavigator from '../../components/PageNavigator';
import TutorialButton from '../../components/TutorialButton';

import { footTypeList } from '../../config/data';

const Column = styled(Col)`
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: ${(props) => (props.borderright ? `1px solid #D8D8D8` : null)};
  border-right: none;
  @media (min-width: 768px) {
    text-align: left;
    border-right: ${(props) =>
    props.borderright ? `1px solid #D8D8D8` : null};
    border-bottom: none;
  }
`;

export default ({ selectorChangeHandler, selector }) => (
  <>
    <Container>
      <Header
        subtitle="orthotic selector | question one"
        title="What is your foot type?"
      />
    </Container>
    <LastSection>
      <Container>
        <TutorialButton text="– Need help with this step? Watch our handy guide for tips & advice." />
        <Row>
          <Column lg="8" md="6" className="mb-5">
            {
              footTypeList.map(footType => (
                <Option
                  key={`foot-type-${footType.id}`}
                  img={footType.img}
                  title={footType.title}
                  text={footType.text}
                  btn={footType.value}
                  selectorChangeHandler={selectorChangeHandler}
                  selector={selector}
                  type="footType"
                />
              ))
            }
            <PageNavigator
              prevURL="/footwatermark"
              nextURL={selector.footType ? '/toedirection' : ''}
            />
          </Column>
        </Row>
      </Container>
      <Footer margintop="2" progressBar={2} />
    </LastSection>
  </>
);
