import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import { theme } from '../config/constants';

export default (props) => {
  const { margintop, progressBar } = props;
  return (
    <Footer margintop={margintop}>
      <FooterContainer>
        <LeftFooter>
          <FooterLogo>
            <img src='/images/header-logo.png' alt='' />
          </FooterLogo>
          <Copyright>{`©${new Date().getFullYear()} Pod Step. All Rights Reserved.`}</Copyright>
        </LeftFooter>
        <RightFooter>
          <a href="https://www.podstep.com.au/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <FooterIcon src='/images/header-icon.png' alt="" />
          <a href="https://www.podstep.com.au/terms-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
        </RightFooter>
      </FooterContainer>
      {
        progressBar &&
        <ProgressBar progressBar={progressBar}/>
      }
    </Footer>
  );
};

const ProgressBar = styled.div`
  background: ${theme.skyBlue};
  border-radius: 3px;
  height: 7px;
  width: ${(props) => props.progressBar/13 * 100 + '%'};
  position: fixed;
  bottom: 0;
  left: 0;
`;

export const LastSection = styled.div`
  background-image: url('/images/footer-bg.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
`;

const Footer = styled.div`
  margin-top: ${(props) => props.margintop}rem;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  background-color: rgba(255, 255, 255, 0.4);
`;

const FooterContainer = styled(Container)`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 1rem;
    padding-bottom: 1.6rem;
  }
`;

const FooterLogo = styled.div`
  width: auto;
  height: 70px;
  margin-bottom: 16px;
  img {
    height: 100%;
  }
  @media (min-width: 768px) {
    margin-bottom: 14px;
    height: 66px;
  }
`;

const Copyright = styled.p`
  font-size: 14px;
  color: ${theme.black};
  line-height: 17px;
  opacity: 0.5;
  margin-bottom: 0;
`;

const LeftFooter = styled.div`
  @media (max-width: 767px) {
    margin-bottom: 5px;
    margin-top: 1.5rem;
    text-align: center;
  }
`;

const RightFooter = styled.div`
  a {
    font-size: 14px;
    color: ${theme.black};
    line-height: 17px;
    opacity: 0.5;
    margin-bottom: 0;
    text-decoration: none !important;
    &:hover {
      color: ${theme.skyBlue};
      opacity: 1;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;
const FooterIcon = styled.img`
  height: 14px;
  width: auto;
  margin: 0 10px;
`;