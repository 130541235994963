import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row, Form } from 'reactstrap';

import Header from '../../components/Header';
import Footer, { LastSection } from '../../components/Footer';
import TutorialButton from '../../components/TutorialButton';
import PageNavigator from '../../components/PageNavigator';
import { postUpload } from '../../API';
import MoonLoader from "react-spinners/MoonLoader";
import { theme } from '../../config/constants';

export default props => {
  const { selectorChangeHandler, selector } = props;
  const [isUploadingImg, setUploadingImg] = useState({ left: false, right: false });

  const onChange = (e) => {
    const isLeftFoot = e.target.name.includes('left');
    const file = e.target.files[0];
    if (file) {
      setUploadingImg({
        left: isLeftFoot,
        right: !isLeftFoot
      });
    }

    const formData = new FormData();
    formData.append('file', file);

    postUpload(formData).then(response => {
      selectorChangeHandler(isLeftFoot ? 'leftFootWatermark' : 'rightFootWatermark', response.data.url);
      setUploadingImg({
        left: false,
        right: false
      });
    });
  };

  return (
    <>
      <Container>
        <Header
          subtitle='Orthotic selector | Initial step'
          title='Upload your foot photo'
        />
      </Container>
      <LastSection>
        <Form>
          <Container>
            <TutorialButton text='– Need help with this step? Watch our handy guide for tips & advice.' />
            <Row>
              <StyledCol lg='8'>
                <Row>
                  <Column xs='6'>
                    <input
                      type='file'
                      id='leftFoot'
                      name='leftFoot'
                      hidden
                      onChange={onChange}
                      disabled={isUploadingImg.left || isUploadingImg.right}
                    />
                    <input
                      type='file'
                      id='rightFoot'
                      name='rightFoot'
                      hidden
                      onChange={onChange}
                      disabled={isUploadingImg.left || isUploadingImg.right}
                    />
                    <FootWatermarkContainer htmlFor='leftFoot'>
                      <FootWatermarkInner image={selector.leftFootWatermark || '/images/foot-left.png'}>
                        {
                          isUploadingImg.left ?
                            (<MoonLoader size={50} color={theme.skyBlue} />) :
                            (
                              !selector.leftFootWatermark && (
                                <UploadText>
                                  <img src='/images/upload-icon.png' alt='' />
                                Upload Left Foot
                                </UploadText>
                              )
                            )
                        }
                      </FootWatermarkInner>
                    </FootWatermarkContainer>
                  </Column>
                  <Column xs='6'>
                    <FootWatermarkContainer htmlFor='rightFoot'>
                      <FootWatermarkInner image={selector.rightFootWatermark || '/images/foot-right.png'}>
                        {
                          isUploadingImg.right ?
                            (<MoonLoader size={50} color={theme.skyBlue} />) :
                            (
                              !selector.rightFootWatermark && (
                                <UploadText>
                                  <img src='/images/upload-icon.png' alt='' />
                                Upload Right Foot
                                </UploadText>
                              )
                            )
                        }
                      </FootWatermarkInner>
                    </FootWatermarkContainer>
                  </Column>
                </Row>
                <InstructionsRow className='mb-5'>
                  <Col md='6'>
                    <InstructionHeader>You will need:</InstructionHeader>
                    <ul>
                      <li>Mobile Camera Device</li>
                      <li>Possible 3rd party to aid in taking photograph</li>
                    </ul>
                    {/* <InstructionHeader>
                      Watermark instructions
                    </InstructionHeader>
                    <p>
                      In bare feet, wet the base of your soles with moisturiser
                      then step squarely on a blank A4 piece of paper. One page
                      each per left and right foot is required.
                    </p> */}
                  </Col>
                  <Col md={6}>
                    <InstructionNote>
                      Note: Try to take photo in the most natural position. This can often be achieved by walking up and down on the spot.
                    </InstructionNote>
                  </Col>
                  <Col md={12}>
                    <InstructionHeader>Upload instructions</InstructionHeader>
                    <p>
                      Select the most suitable photo that highlights the problem area. Store the photo on your computer to a familiar location. Upload the photo to the application by selecting the Left or Right foot icon.
                    </p>
                    <p>
                      Throughout the application you will have the opportunity to comment and focus on the uploaded image.
                    </p>
                  </Col>
                </InstructionsRow>
                <PageNavigator
                  prevURL='/'
                  nextURL='/foottype'
                  prevHidden
                  firstStep
                />
              </StyledCol>
              <Col md='4'></Col>
            </Row>
          </Container>
          <Footer margintop='5' progressBar={1} />
        </Form>
      </LastSection>
    </>
  );
};


const InstructionsRow = styled(Row)`
  margin-top: 20px;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 26px;
`;

const InstructionNote = styled.p`
  margin-top: 20px;
  margin-bottom: 10px;
  color: #0b96b8;
  font-style: italic;
`;

const InstructionHeader = styled.h4`
  color: #4c5b69;
  font-family: 'Montserrat Bold';
  font-size: 21px;
  font-weight: bold;
  line-height: 32px;
  margin-top: 20px;
`;

const Column = styled(Col)`
  @media (max-width: 420px) {
    flex: unset !important;
    max-width: 100% !important;
    margin-bottom: 30px;
  }
`;

const FootWatermarkContainer = styled.label`
  cursor: pointer;
  display: block;
  height: 340px;
  width: 90%;
  margin: auto;
  padding: 25px 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 36px 0 rgba(1, 28, 47, 0.1);
  @media (min-width: 421px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    height: 400px;
  }
  @media (min-width: 1200px) {
    height: 420px;
    width: 90%;
  }
  &:hover {
    box-shadow: 0 2px 36px 0 rgba(1, 28, 47, 0.17);
  }
`;

const FootWatermarkInner = styled.div`
  background-image: url('${(props) => props.image}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const UploadText = styled.div`
  color: #00d0ff;
  font-family: 'Montserrat Bold';
  font-size: 21px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  img {
    display: block;
    width: 33px;
    margin: auto;
    margin-bottom: 15px;
  }
`;

const StyledCol = styled(Col)`
  @media (min-width: 992px) {
    padding-right: 60px !important;
    border-right: 1px solid #d8d8d8;
  }
`;