import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { Input } from 'reactstrap';
import { isEmpty } from 'lodash';

export const DarkBlueButton = styled.button`
  font-family: 'Montserrat Black';
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 3px;
  line-height: 17px;
  text-align: center;
  border-radius: 31px;
  padding: ${(props) => (props.small ? '0.65rem 1.2rem' : '0.75rem 1.4rem')};
  min-height: ${(props) => (props.small ? '41px' : '60px')};
  width: ${(props) => (props.small ? '100%' : '230px')};
  background-color: #ffffff;
  color: ${(props) => props.theme.darkBlue};
  border: 2px solid ${(props) => props.theme.darkBlue};
  &.active,
  &:hover,
  :focus {
    background-color: ${(props) => props.theme.darkBlue};
    color: #ffffff;
  }
`;

export const SkyBlueButton = styled.button`
  font-family: 'Montserrat Black';
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 3px;
  line-height: 17px;
  text-align: center;
  border-radius: 31px;
  padding: ${(props) => (props.small ? '0.65rem 1.2rem' : '0.75rem 1.4rem')};
  min-height: ${(props) => (props.small ? '41px' : '60px')};
  width: ${(props) => (props.small ? '100%' : '230px')};
  background-color: #ffffff;
  color: ${(props) => props.theme.skyBlue};
  border: 2px solid ${(props) => props.theme.skyBlue};
  &:hover,
  &.active {
    background-color: ${(props) => props.theme.skyBlue};
    color: #ffffff;
  }
`;

export const RedButton = styled.button`
  font-family: 'Montserrat Black';
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 3px;
  line-height: 17px;
  text-align: center;
  border-radius: 31px;
  padding: ${(props) => (props.small ? '0.65rem 1.2rem' : '0.75rem 1.4rem')};
  min-height: ${(props) => (props.small ? '41px' : '60px')};
  width: ${(props) => (props.small ? '100%' : '230px')};
  background-color: #ffffff;
  color: ${(props) => props.theme.red};
  border: 2px solid ${(props) => props.theme.red};
  &:hover,
  :focus {
    background-color: ${(props) => props.theme.red};
    color: #ffffff;
  }
`;

export const RedButtonSold = styled.button`
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  background-color: ${(props) => props.theme.red};
  color: #fff;
  border: 2px solid ${(props) => props.theme.red};
  text-align: center;
  border-radius: 31px;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 3px;
  padding: 1.2rem 2rem;
  &:hover,
  :focus {
    background-color: #fff;
    color: ${(props) => props.theme.red};
  }
`;

const Tag = styled.span`
  font-family: 'Montserrat Black';
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 2.14px;
  line-height: 17px;
  text-align: center;
  border-radius: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.4rem;
  min-height: 36px;
  min-width: 200px;
  background-color: #ffffff;
  cursor: grab;
`;

export const SkyBlueTag = styled(Tag)`
  color: ${(props) => props.theme.skyBlue};
  border: 2px solid ${(props) => props.theme.skyBlue};
  &:hover,
  :focus {
    background-color: ${(props) => props.theme.skyBlue};
    color: #ffffff;
  }
`;

export const GrayTag = styled(Tag)`
  color: #ffffff;
  border: 2px solid #979797;
  background-color: #979797;
`;

const CheckboxContainer = styled.div`
  position: relative;
  display: inline-block;
  text-align: left;
  opacity: ${props => props.disabled ? 0.8 : 1 };
`;

const CheckboxLabel = styled.label`
  font-family: ${(props) =>
    props.bold ? `'Montserrat Bold'` : `'Montserrat Regular'`};
  font-size: ${(props) => (props.bold ? `21px` : `15px`)};
  color: ${(props) => props.theme.gray};
  user-select: none;
  margin-left: 45px;
  line-height: 21px;
  letter-spacing: 0.5px;
  cursor: ${props => props.disabled ? 'default' : 'pointer' };
  span {
    font-family: 'Montserrat Regular';
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    position: relative;
    top: -1px;
  }
  &:before {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 0;
    bottom: 0;
    margin: auto;
    top: -8px;
    background: url(${(props) =>
        props.notrounded
          ? `/images/unchecked-box-alt.svg`
          : `/images/unchecked-box.svg`})
      no-repeat;
    background-size: cover;
    transition: all 0.17s ease-in-out;
  }
`;

const CheckboxIcon = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  &:checked + label::before {
    background: url(${(props) =>
        props.notrounded
          ? `'/images/checked-box-alt.svg'`
          : `'/images/checked-box.svg'`})
      no-repeat;
    background-size: cover;
  }
`;

export const Checkbox = ({
  id,
  label,
  labelhelper,
  notrounded,
  bold,
  checked,
  onChange,
  disabled,
}) => (
  <CheckboxContainer disabled={disabled}>
    <CheckboxIcon
      type='checkbox'
      id={id}
      notrounded={notrounded ? true : false}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <CheckboxLabel
      htmlFor={id}
      notrounded={notrounded ? true : false}
      bold={bold ? true : false}
      disabled={disabled}
    >
      {label} <span>{labelhelper}</span>
    </CheckboxLabel>
  </CheckboxContainer>
);

const Selector = styled(Select)`
  width: 100%;
  font-size: 15px;
  .Select__value-container {
    padding: 0 0.8rem;
  }
  .Select__dropdown-indicator {
    svg {
      transition: all 0.2s ease-in-out;
      path {
        color: #0b96b8;
      }
    }
  }
  .Select__control--menu-is-open {
    .Select__dropdown-indicator {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .Select__indicator-separator {
    display: none;
  }
  .Select__placeholder {
    color: ${(props) => props.theme.gray};
    margin-right: 0;
    margin-left: 0;
  }
  .css-b8ldur-Input {
    margin: 0;
  }
  .Select__control {
    transition: all 0.2s ease-in-out;
  }
  .Select__control--is-focused,
  .Select__control:hover {
    border-color: ${(props) => props.theme.darkBlue};
    box-shadow: 0 0 0 1px ${(props) => props.theme.darkBlue};
  }
  & > .Select__menu {
    border: 1px solid #dddddd;
    border-radius: 5px;
    box-shadow: 0 2px 20px 1px rgba(1, 28, 47, 0.1);
    background-color: white;
    div {
      letter-spacing: 0.5px;
      font-size: 16px;
      width: 100%;
    }
  }
  @media (min-width: 992px) {
    & > .Select__menu {
      top: -8px;
      height: auto;
      margin-left: 1.5%;
    }
    & > div {
      display: inline-flex;
      height: 48px;
      width: ${(props) => props.fullWidth ? '100%' : '48%'};
    }
    .Select__control--menu-is-open {
      .Select__dropdown-indicator {
        svg {
          transform: rotate(90deg);
        }
      }
    }
    .Select__dropdown-indicator {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
`;

const selectStyle = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected
      ? 'rgba(11, 150, 184, 0.5) !important'
      : state.isFocused
      ? '#0B96B8 !important'
      : null,
    color: state.isSelected ? 'white' : state.isFocused ? 'white' : '#4C5B69',
  }),
};

export const StyledSelector = ({ fullWidth, options, placeholder, onChange, value }) => {
  let selected = [];
  if(!options[0].options) {
    selected = options.filter((option) => (option.value === value))
  } else {
    for (var ndx = 0; ndx < options.length; ndx++) {
      selected = options[ndx].options.filter((option) => (
        option.value === value
      ));
      if(!isEmpty(selected)) break;
    }
  }

  return (
  <Selector
    fullWidth={fullWidth}
    options={options}
    placeholder={placeholder}
    classNamePrefix={'Select'}
    styles={selectStyle}
    onChange={onChange}
    value={selected}
  />
);
}

export const StyledInput = styled(Input)`
  &.form-control {
    border-radius: 4px;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    transition: all 0.2s ease-in-out;
    height: 51px;
    font-size: 15px;
    color: ${(props) => props.theme.gray};
    padding: 0 0.8rem;
    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.darkBlue};
      box-shadow: 0 0 0 1px ${(props) => props.theme.darkBlue};
    }
  }
  &.not-rounded {
    border-radius: 0;
  }
  &.square {
    height: 48px;
    width: 48px;
  }
`;
