import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';
import queryString from 'query-string';
import styled from 'styled-components';

import FootType from './FootType';
import FootPain from './FootPain';
import FootWatermark from './FootWatermark';
import Introduction from './Introduction';
import KneePosition from './KneePosition';
import LateralCurvature from './LateralCurvature';
import MedicalHistory from './MedicalHistory';
import NavicularBulging from './NavicularBulging';
import OrthoticPrescription from './OrthoticPrescription';
import PersonalDetails from './PersonalDetails';
import RearView from './RearView';
import StructuralAbnormalities from './StructuralAbnormalities';
import TalarHeadPalpation from './TalarHeadPalpation';
import ToeDirection from './ToeDirection';
import { getProducts } from '../../API';
import {
  base_device as baseDevice,
  shoe_type as shoeTypeData,
  footTypeList,
  toeDirectionList,
  rearViewList,
  navicularBulgingList,
  lateralCurvatureList,
  talarheadPalpationList,
  kneePositionList
} from '../../config/data';
import { find } from 'lodash';

const Homepage = styled.div`
  padding-top: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
  @media (min-width: 768px) {
    background-size: 90%;
  }
`;

export default ({ user, updateUser, addToCart, location }) => {
  const [products, setProducts] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [recommended, setRecommended] = useState(null);
  const [loadingRecommend, setLoadingRecommend] = useState(false);
  const [selector, setSelector] = useState({
    isCustomFit: false,
    evaDensity: '',
    deviceWidth: '',
    footType: '',
    toeDirection: '',
    childsFoot: false,
    rearView: '',
    navicularBulging: '',
    lateralCurvature: '',
    talarheadPalpation: '',
    kneePosition: '',
    medicalHistoryDiabetes: '',
    medicalHistoryArthritis: '',
    medicalHistoryOther: '',
    footPainPlantar: [],
    footPainLateral: [],
    footPainDorsal: [],
    bodyWeight: '',
    shoeSize: '',
    shoeType: '',
    leftFootLP: '',
    leftFootWP: '',
    rightFootLP: '',
    rightFootWP: '',
    leftFootWatermark: null,
    rightFootWatermark: null,
    structuralAbnormalities: {
      leftFootIssues: [],
      rightFootIssues: [],
      legLengthDifference: '',
    },
    archHeight: 20,
    shellLength: 35,
    shellWidth: 10,
    shellThickness: 4,
    topCover: '',
    rearFootAngle: 3.5,
  });

  const computeRearAngle = (updatedSelector) => {
    const isKneePositionPage = location.pathname === '/kneeposition';

    const {
      footType,
      toeDirection,
      rearView,
      navicularBulging,
      lateralCurvature,
      talarheadPalpation,
      kneePosition,
    } = updatedSelector;
    let updateRearAngle = Math.round(3.5 * 2) / 2;

    const getResultValue = (array, value) => {
      const data = find(array, { value })
      return data ? data.id : null
    };

    const findIndex = {
      footType: getResultValue(footTypeList, footType),
      toeDirection: getResultValue(toeDirectionList, toeDirection),
      rearView: getResultValue(rearViewList, rearView),
      navicularBulging: getResultValue(navicularBulgingList, navicularBulging),
      lateralCurvature: getResultValue(lateralCurvatureList, lateralCurvature),
      talarheadPalpation: getResultValue(talarheadPalpationList, talarheadPalpation),
      kneePosition: getResultValue(kneePositionList, kneePosition),
    };

    Object.keys(findIndex).forEach((key) => {
      const val = findIndex[key];
      if (val === 1) {
        updateRearAngle = updateRearAngle + 0.35;
      } else if (val === 3) {
        updateRearAngle = updateRearAngle - 0.15;
      }
    });

    if (isKneePositionPage) {
      // round off to nearest whole number
      updateRearAngle = Math.round(updateRearAngle * 10) / 10;
    }

    return updateRearAngle;
  }

  const selectorChangeHandler = (field, value) => {
    const updatedSelector = {
      ...selector, [field]: value,
    };

    const updatedRearAngle = Number(computeRearAngle(updatedSelector));

    updatedSelector.rearFootAngle = updatedRearAngle;

    setSelector(updatedSelector);
  };

  const getBaseDevice = () => {
    const { childsFoot, rearFootAngle, shoeType, toeDirection } = selector;

    let baseDeviceType = baseDevice.unknown;

    if (rearFootAngle > 6) {
      baseDeviceType = baseDevice.flangeSkieveHeel;
    } else if (rearFootAngle > 5.5) {
      baseDeviceType = baseDevice.flangeSkieve;
    } else if (rearFootAngle > 5) {
      baseDeviceType = baseDevice.skieve;
    } else if (rearFootAngle > 0 && rearFootAngle <= 5) {
      // Check conditions
      // if (childsFoot && toeDirection === toeDirectionList[0].value) {
      //   baseDeviceType = baseDevice.gaitPlateInToe;
      // } else if (childsFoot && toeDirection === toeDirectionList[2].value) {
      //   baseDeviceType = baseDevice.gaitPlateOutToe;
      // Removed childs foot, show kids device if kids checbox is checked
      if ([shoeTypeData[5].value, shoeTypeData[3].value].includes(shoeType)) {
        baseDeviceType = baseDevice.hook;
      } else if ([shoeTypeData[4].value, shoeTypeData[1].value, shoeTypeData[2].value].includes(shoeType)) {
        baseDeviceType = baseDevice.lowProfile;
      } else if ([shoeTypeData[1].value, shoeTypeData[2].value].includes(shoeType)) {
        baseDeviceType = baseDevice.narrowFitting;
      } else {
        baseDeviceType = baseDevice.moodRootShell;
      }
    }

    if (childsFoot && toeDirection === toeDirectionList[0].value) {
        baseDeviceType = baseDevice.gaitPlateOutToe;
    } else if (childsFoot && toeDirection === toeDirectionList[2].value) {
      baseDeviceType = baseDevice.gaitPlateInToe;
    } else if (childsFoot) {
      baseDeviceType = baseDevice.kids;
    }

    return baseDeviceType;
  };

  useEffect(() => {
    // Fetch products once
    setLoadingRecommend(true);

    getProducts().then(response => {
      if (response.data.length > 0) {
        setProducts(response.data);
      }
    }).finally(() => {
      setLoadingRecommend(false)
    });
    // setProducts(ProductsJSON);
  }, []);

  useEffect(() => {
    const query = queryString.parse(location.search);

    if (query['custom-fit']) {
      selectorChangeHandler('isCustomFit', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (isSaved || window.location.pathname === '/') {
  //     window.onbeforeunload = null;
  //   } else {
  //     window.onbeforeunload = () => true;
  //   }
  // }, [isSaved]);

  useEffect(() => {
    const selectedBaseDevice = getBaseDevice();

    const recommendedProduct = products.find(product => {

      if (product && product.base_device) {
        return product.base_device === selectedBaseDevice;
      }

      return null;
    })

    if (recommendedProduct) setRecommended(recommendedProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector]);

  return (
    <Homepage style={{ backgroundImage: 'url(/images/header-bg.png)' }}>
      <Router>
        <Introduction
          selector={selector}
          selectorChangeHandler={selectorChangeHandler}
          user={user} path="/"
        />
        <FootType
          path="foottype"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <ToeDirection
          path="toedirection"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <RearView
          path="rearview"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <NavicularBulging
          path="navicularbulging"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <LateralCurvature
          path="lateralcurvature"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <TalarHeadPalpation
          path="talarheadpalpation"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <KneePosition
          path="kneeposition"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <StructuralAbnormalities
          path="structuralabnormalities"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <MedicalHistory
          path="medicalhistory"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <FootPain
          path="footpain"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <PersonalDetails
          path="personaldetails"
          selectorChangeHandler={selectorChangeHandler}
          setSelector={setSelector}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <FootWatermark
          path="footwatermark"
          selectorChangeHandler={selectorChangeHandler}
          selector={selector}
          recommended={recommended}
          isLoading={loadingRecommend}
        />
        <OrthoticPrescription
          path="orthoticprescription"
          selectorChangeHandler={selectorChangeHandler}
          updateUser={updateUser}
          selector={selector}
          setIsSaved={setIsSaved}
          recommended={recommended}
          isLoading={loadingRecommend}
          baseDevice={getBaseDevice()}
          addToCart={addToCart}
          isSaved={isSaved}
          user={user}
        />
      </Router>
    </Homepage>
  );
};
