import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PlantarOne } from '../config/plantar/plantar-1.svg';
import { ReactComponent as PlantarTwo } from '../config/plantar/plantar-2.svg';
import { ReactComponent as PlantarThree } from '../config/plantar/plantar-3.svg';
import { ReactComponent as PlantarFour } from '../config/plantar/plantar-4.svg';
import { ReactComponent as PlantarFive } from '../config/plantar/plantar-5.svg';
import { ReactComponent as PlantarSix } from '../config/plantar/plantar-6.svg';
import { ReactComponent as PlantarSeven } from '../config/plantar/plantar-7.svg';
import { ReactComponent as PlantarEight } from '../config/plantar/plantar-8.svg';
import { ReactComponent as PlantarNine } from '../config/plantar/plantar-9.svg';
import { find } from 'lodash';

export default ({ footPainPlantar, onSelectPain, viewOnly }) => {

  return (
    <OptionImg viewonly={viewOnly}>
      <Plantar1
        onClick={() => !viewOnly && onSelectPain(1)}
        className={find(footPainPlantar, { regionNo: 1 }) && 'active'}
      />
      <Plantar2
        onClick={() => !viewOnly && onSelectPain(2)}
        className={find(footPainPlantar, { regionNo: 2 }) && 'active'}
      />
      <Plantar3
        onClick={() => !viewOnly && onSelectPain(3)}
        className={find(footPainPlantar, { regionNo: 3 }) && 'active'}
      />
      <Plantar4
        onClick={() => !viewOnly && onSelectPain(4)}
        className={find(footPainPlantar, { regionNo: 4 }) && 'active'}
      />
      <Plantar5
        onClick={() => !viewOnly && onSelectPain(5)}
        className={find(footPainPlantar, { regionNo: 5 }) && 'active'}
      />
      <Plantar6
        onClick={() => !viewOnly && onSelectPain(6)}
        className={find(footPainPlantar, { regionNo: 6 }) && 'active'}
      />
      <Plantar7
        onClick={() => !viewOnly && onSelectPain(7)}
        className={find(footPainPlantar, { regionNo: 7 }) && 'active'}
      />
      <Plantar8
        onClick={() => !viewOnly && onSelectPain(8)}
        className={find(footPainPlantar, { regionNo: 8 }) && 'active'}
      />
      <Plantar9
        onClick={() => !viewOnly && onSelectPain(9)}
        className={find(footPainPlantar, { regionNo: 9 }) && 'active'}
      />
    </OptionImg>
  )
}

const Plantar1 = styled(PlantarOne)`
  top: 108px;
  left: 67px;
`;
const Plantar2 = styled(PlantarTwo)`
  top: 93px;
  left: 115px;
`;
const Plantar3 = styled(PlantarThree)`
  top: 94px;
  left: 170px;
`;
const Plantar4 = styled(PlantarFour)`
  top: 194px;
  left: 67px;
`;
const Plantar5 = styled(PlantarFive)`
  top: 195px;
  left: 154px;
`;
const Plantar6 = styled(PlantarSix)`
  top: 267px;
  left: 82px;
  z-index: 1;
`;
const Plantar7 = styled(PlantarSeven)`
  top: 267px;
  left: 151px;
`;
const Plantar8 = styled(PlantarEight)`
  top: 346px;
  left: 99px;
  z-index: 1;
`;
const Plantar9 = styled(PlantarNine)`
  top: 368px;
  left: 131px;
`;

const OptionImg = styled.div`
  background: url(/images/plantar.png);
  box-sizing: border-box;
  background-size: cover;
  background-color: #ffffff;
  position: relative;
  height: 490px;
  width: 300px;
  margin-bottom: 35px;
  margin-top: 25px;
  box-shadow: 0 2px 36px 0 rgba(1, 28, 47, 0.1);
  svg {
    position: absolute;
    &:hover {
      path {
        fill: ${props => props.viewonly ? 'unset' : 'rgba(0, 0, 0, 0.1)'};
      }
    }
    &.active {
      path {
        fill: #0a96b7cf;
        &.label-svg {
          fill: #fec192;
        }
      }
    }
    path {
      cursor: ${props => props.viewonly ? 'default' : 'pointer'};
      transition: all 0.2s ease-in-out;
      fill: transparent;
      &.label-svg {
        fill: #0a96b7cf;
      }
    }
  }
  @media (max-width: 767px) {
    margin: auto;
    margin-bottom: 35px;
    margin-top: 25px;
  }
`;