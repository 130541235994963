import React from 'react';
import styled from 'styled-components';

const Tagline = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem 0.5rem;
  padding-bottom: 3rem;
  @media (min-width: 768px) {
    padding: 6rem;
    padding-bottom: 3rem;
  }
`;

const Subtitle = styled.p`
  font-family: 'Montserrat Bold';
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2.79px;
  text-align: center;
  margin-bottom: 1.3rem;
  color: ${(props) => props.theme.darkBlue};
`;

const Title = styled.h1`
  font-family: 'Montserrat Bold';
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 1.6rem;
  color: ${(props) => props.theme.black};
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 47px;
  }
`;

const Icon = styled.img`
  height: 20px;
  width: auto;
`;

const HeaderContainer = styled.div`
  text-align: center;
`;

export default (props) => {
  const { subtitle, title } = props;
  return (
    <Tagline>
      <HeaderContainer>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
        <Icon src='/images/header-icon.png' alt='' />
      </HeaderContainer>
    </Tagline>
  );
};
