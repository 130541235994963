import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer, { LastSection } from '../../components/Footer';
import PageNavigator from '../../components/PageNavigator';
import PlantarView from '../../components/PlantarView';
import LateralView from '../../components/LateralView';
import DorsalView from '../../components/DorsalView';
import Recommendation from '../../components/Recommendation';
// import ChatSupport from '../../components/ChatSupport';

const Column = styled(Col)`
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: ${(props) => (props.borderright ? `1px solid #D8D8D8` : null)};
  border-right: none;
  @media (min-width: 768px) {
    text-align: left;
    border-right: ${(props) =>
      props.borderright ? `1px solid #D8D8D8` : null};
    border-bottom: none;
  }
`;

const RowContainer = styled(Row)`
  justify-content: center;
`;

export default ({ selectorChangeHandler, selector, recommended, isLoading }) => (
  <>
    <Container>
      <Header
        subtitle="orthotic selector | Question eleven"
        title="Find your foot pain"
      />
    </Container>
    <LastSection>
      <Container>
        <RowContainer>
          <Column lg="8" md="7" className="mb-5" borderright="true">
            <PlantarView
              title="A. Plantar –<br/>Click where it hurts:"
              question="Then select from the list:"
              selectorChangeHandler={selectorChangeHandler}
              selector={selector}
              type="footPainPlantar"
            />
            <LateralView
              title='B. Lateral View –<br/>Click where it hurts:'
              question='Then select from the list:'
              selectorChangeHandler={selectorChangeHandler}
              selector={selector}
              type='footPainLateral'
            />
            <DorsalView
              title='C. Dorsal View –<br/>Click where it hurts:'
              question='Then select from the list:'
              selectorChangeHandler={selectorChangeHandler}
              selector={selector}
              type='footPainDorsal'
            />
            <PageNavigator
              prevURL='/medicalhistory'
              nextURL='/personaldetails'
              skipURL='/personaldetails'
            />
          </Column>
          <Column lg='4' md='5' className='mb-5 text-center'>
            <Recommendation isLoading={isLoading} recommended={recommended} />
          </Column>
        </RowContainer>
      </Container>
      <Footer margintop='2' progressBar={11}/>
      {/* <ChatSupport /> */}
    </LastSection>
  </>
);
