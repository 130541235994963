import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer, { LastSection } from '../../components/Footer';
import PageNavigator from '../../components/PageNavigator';
import Option from '../../components/Option';
import Recommendation from '../../components/Recommendation';
import TutorialButton from '../../components/TutorialButton';

const Column = styled(Col)`
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: ${(props) => (props.borderright ? `1px solid #D8D8D8` : null)};
  border-right: none;
  @media (min-width: 768px) {
    text-align: left;
    border-right: ${(props) =>
    props.borderright ? `1px solid #D8D8D8` : null};
    border-bottom: none;
  }
`;

const RowContainer = styled(Row)`
  justify-content: center;
`;

export default ({
  isLoading,
  recommended,
  selector,
  selectorChangeHandler,
}) => (
  <>
    <Container>
      <Header
        subtitle="orthotic selector | question four"
        title="Rear foot drop"
      />
    </Container>
    <LastSection>
      <Container>
        <TutorialButton text="– Need help with this step? Watch our handy guide for tips & advice." />
        <RowContainer>
          <Column lg="8" md="6" className="mb-5" borderright="true">
            <Option
              img="/images/navicularbulging/navicularbulging1.png"
              title="Option One.<br/>Excessive bulging"
              btn="everted"
              selectorChangeHandler={selectorChangeHandler}
              selector={selector}
              type="navicularBulging"
            />
            <Option
              img="/images/navicularbulging/navicularbulging3.png"
              title="Option Two.<br/>Moderate bulging"
              btn="moderate"
              selectorChangeHandler={selectorChangeHandler}
              selector={selector}
              type="navicularBulging"
            />
            <Option
              img="/images/navicularbulging/navicularbulging2.png"
              title="Option Three.<br/>Neutral"
              btn="neutral"
              selectorChangeHandler={selectorChangeHandler}
              selector={selector}
              type="navicularBulging"
            />
            <PageNavigator
              prevURL="/rearview"
              nextURL={selector.navicularBulging ? '/lateralcurvature' : ''}
              skipURL="/lateralcurvature"
            />
          </Column>
          <Column lg="4" md="6" className="mb-5 text-center">
            <Recommendation isLoading={isLoading} recommended={recommended} />
          </Column>
        </RowContainer>
      </Container>
      <Footer margintop="2" progressBar={5} />
    </LastSection>
  </>
);
