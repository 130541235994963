import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { Container, Row, Col } from 'reactstrap';
import { DarkBlueButton, SkyBlueButton, RedButton } from '../../config/styles';
import { StyledSelector } from '../../config/styles';
import Header from '../../components/Header';
import Footer, { LastSection } from '../../components/Footer';
import { isEmpty, filter } from 'lodash';
import { theme } from '../../config/constants';
import { getUserPrescriptions } from '../../API';

export default ({ selector, selectorChangeHandler, user }) => {
  const [selectedPrescription, setSelectedPrescription] = useState();
  const [prescriptions, setPrescriptions] = useState([]);

  useEffect(() => {
    if (user) {
      let userDetails = user;

      try {
        userDetails = JSON.parse(user);
      } catch (error) {
        console.log('Failed to parse user', error);
      }

      getUserPrescriptions(userDetails.id).then(response => {
        const items = response.data.data;
        const prescList = items && items.map((item) => {
          const presc = {
            value: item.id,
            label: item.base_device + '˚',
            custom_fit: item.custom_fit,
          };
          return presc;
        });
        setPrescriptions(prescList);
      })
    }
  }, [user]);

  const onSelectPrescription = (e) => {
    const id = e.value;

    setSelectedPrescription(id);

    navigate(`/prescription/${id}`);
  };

  const redirectTo = e => {
    const { name } = e.target;

    selectorChangeHandler('isCustomFit', name === 'custom');

    navigate('/footwatermark');
  };

  const redirectToShop = () => {
    navigate('/product');
  };

  return (
    <>
      <Container>
        <Header
          subtitle='Take the next step'
          title='Find your perfect fit by selecting an option below'
        />
      </Container>
      <LastSection>
        <Container>
          <RowContainer>
            <Column lg='4' md='6' sm='6' className='mb-5'>
              <FeatureContainer>
                <FeatureTitle>Select Personalised</FeatureTitle>
                <FeatureImg src='/images/feature1.png' alt='' />
                <FeatureDescription>
                  Body weight will determine Shell thickness or EVA Density
                  (Depending on the style of Orthotic Poly or EVA)
                </FeatureDescription>
                <FeaturePrice>$250.00</FeaturePrice>
                {
                  (user && !isEmpty(filter(prescriptions, { custom_fit: false }))) && (
                    <StyledSelector1>
                      <StyledSelector
                        options={filter(prescriptions, { custom_fit: false })}
                        placeholder='Saved prescriptions'
                        onChange={(e) => onSelectPrescription(e)}
                        value={selectedPrescription}
                      />
                    </StyledSelector1>
                  )
                }
                <DarkBlueButton name="personalised" onClick={redirectTo}>Personalised device</DarkBlueButton>
              </FeatureContainer>
            </Column>
            <Column lg='4' md='8' sm='8' className='mb-5' mobilebottom='true'>
              <FeatureContainer withBackground='true'>
                <FeatureTitle>Select Custom</FeatureTitle>
                <FeatureImg src='/images/feature2.png' alt='' />
                <FeatureDescription>
                  FOOT CASTING thickness or EVA Density (Depending on the style of
                  Orthotic Poly or EVA)
                </FeatureDescription>
                <FeaturePrice>$375.00</FeaturePrice>
                {
                  (user && !isEmpty(filter(prescriptions, { custom_fit: true }))) && (
                    <StyledSelector2>
                      <StyledSelector
                        options={filter(prescriptions, { custom_fit: true })}
                        placeholder='Saved custom'
                        onChange={(e) => onSelectPrescription(e)}
                        value={selectedPrescription}
                      />
                    </StyledSelector2>
                  )
                }
                <SkyBlueButton
                  className="mb-4"
                  name="custom"
                  onClick={redirectTo}
                >
                  custom fit device
                </SkyBlueButton>
              </FeatureContainer>
            </Column>
            <Column lg='4' md='6' sm='6' className='mb-5'>
              <FeatureContainer>
                <FeatureTitle>Select Off-the-shelf</FeatureTitle>
                <FeatureImg src='/images/feature1.png' alt='' />
                <FeatureDescription>
                  SHOP ONLINE <br />
                  Body weight will determine Shell thickness or EVA Density
                </FeatureDescription>
                <FeaturePrice>From $70.00</FeaturePrice>
                <RedButton onClick={() => redirectToShop()}>visit shop</RedButton>
              </FeatureContainer>
            </Column>
          </RowContainer>
        </Container>
        <Footer margintop='13' />
      </LastSection>
    </>
  );
}

const Column = styled(Col)`
  @media (max-width: 991px) and (min-width: 576px) {
    ${(props) => (props.mobilebottom ? `order: 2;` : `order: 1;`)}
  }
`;

const RowContainer = styled(Row)`
  justify-content: center;
`;

const FeatureContainer = styled.div`
  text-align: center;
  padding: 2rem 1rem;
  ${(props) =>
    props.withBackground
      ? `background-color: rgba(0,208,255,0.15);
      border-radius: 12px;
      padding-bottom: 5rem;
      &:after{
        content: "Highly Recommended";
        font-family: "Montserrat Regular";
        color: white;
        font-size: 18px;
        letter-spacing: 4px;
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        margin: 1rem 1rem 0;
        left: 0;
        right: 0;
        background-color: ${props.theme.skyBlue};
        border-radius: 0 0 12px 12px;
        padding: 0.5rem;
      }`
      : null};
`;

const FeatureTitle = styled.h3`
  font-family: 'Montserrat Bold';
  font-size: 21px;
  margin-bottom: 40px;
  color: ${(props) => props.theme.black};
`;

const FeatureImg = styled.img`
  box-sizing: border-box;
  border: 15px solid #ffffff;
  background-color: #ffffff;
  border-radius: 100%;
  width: 100%;
  height: auto;
  max-width: 220px;
  margin-bottom: 40px;
  box-shadow: 0 2px 36px 0 rgba(1, 28, 47, 0.1);
`;

const FeatureDescription = styled.p`
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;
  margin-bottom: 35px;
  min-height: 80px;
  color: ${(props) => props.theme.gray};
`;

const FeaturePrice = styled.p`
  font-size: 21px;
  letter-spacing: 0.66px;
  line-height: 18px;
  margin-bottom: 30px;
  color: ${(props) => props.theme.black};
`;

const StyledSelector1 = styled.div`
  width: 230px;
  margin: auto;
  margin-bottom: 2rem;

  .Select__control {
    width: 100%;
    border-color: ${theme.darkBlue};
    box-shadow: 0 0 0 1px ${theme.darkBlue};
  }

  .Select__menu {
    width: 100%;
  }
`;

const StyledSelector2 = styled.div`
  width: 230px;
  margin: auto;
  margin-bottom: 2rem;

  .Select__control {
    width: 100%;
    border-color: ${theme.skyBlue};
    box-shadow: 0 0 0 1px ${theme.skyBlue};
  }

  .Select__menu {
    width: 100%;
  }

  .Select__control:hover {
    border-color: ${theme.skyBlue};
    box-shadow: 0 0 0 1px ${theme.skyBlue};
  }
`;